import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Carousel from "react-multi-carousel";
import { Helmet } from "react-helmet";
import { isEmpty } from 'lodash';
import Popup from 'reactjs-popup';
import ReactNotifications from 'react-notifications-component';
import HeaderMyCoach from '../../pages/mycoach/HeaderMyCoach'
import common from '../../common';
import actions from '../../redux/actions';
import mixpanel from 'mixpanel-browser';
import AssessmentPreview from '../../components/study-material/CommonComponents';
import MonthTable from '../../components/temp';
import Profile from '../../public/icons/profile.svg';
import PathD from '../../public/icons/path-d.svg';
import PathB from '../../public/icons/path-b.svg';
import CodeLab from '../../public/icons/code-lab.svg';
import TestPrep from '../../public/icons/test-prep.svg';
import Coding from '../../public/icons/coding.svg';
import CourseImg from '../../public/images/courses.svg';
import Empty from '../../components/error/Empty';
import Right from '../../public/icons/right-arrow-black.svg';
import Frame10 from '../../public/icons/Frame-10.svg';
import Frame11 from '../../public/icons/Frame-11.svg';
import Frame12 from '../../public/icons/Frame-12.svg';
import Frame8 from '../../public/icons/Frame-8.svg';
import Frame9 from '../../public/icons/Frame-9.svg';
import Target from '../../public/icons/target.svg';
import modelBanner from '../../public/images/model-banner.jpg';
import mainBanner from '../../public/images/model-banner.jpg';
import facebookIcon from '../../public/images/facebook-icon.png';
import linkedinIcon from '../../public/images/linkedin-icon.png';
import instagramIcon from '../../public/images/instagram-icon.png';
import BackArrow from '../../public/icons/mycoach-backarrow.svg';
import arrowicon from '../../public/icons/mycoach-arrow-icon.png';
import goalicon from '../../public/icons/mycoach-goal-icon.png';
import Assignment from '../../public/icons/assignment.svg';
import OOPS from '../../public/icons/WiredMobile.png';
import Assessment from '../../public/icons/target.svg';
import AccordianArrow from '../../public/icons/right-arrow-black.svg';

import StudioProjectIcon from '../../public/icons/studio-project-icon.png';
import 'react-notifications-component/dist/theme.css';
import "react-multi-carousel/lib/styles.css";
import './styles.css';



const responsive = {
  desktop: {
    breakpoint: { max: 4240, min: 900 },
    items: 3,
    slidesToSlide: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 590 },
    items: 2,
    slidesToSlide: 2
  },
};

class StartTimer extends Component {
  constructor(props) {
    super(props)
    this.startTime = (new Date(this.props.startTime)).getTime();
    //this.second = 1000;
    //this.minute = this.second * 60;
    //this.hour = this.minute * 60;
    //this.postQuestions = []
    this.state = {
      //currentTime: props.startTime,
      differenceTime: this.props.count
    }
  }

  componentDidMount() {
    this.timer = setInterval(() => this.getCurrentTime(), 1000)
  }

  getCurrentTime = () => {

    let end = new Date();
    this.distance = this.startTime - end.getTime();
    //this.distance =  this.startTime - 10 * 60 * 1000;

    if (this.distance > 0)
      this.props.onChange(this.distance, this.state.differenceTime)
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  startTimerForNextQuestion = () => {

    this.startTime = (new Date()).getTime() - (this.props.time_taken * 1000);
    this.timer = setInterval(() => this.getCurrentTime(), 1000)
  }

  render() {
    return (
      <span className="timer" style={this.props.notify > 0 ? { color: '#ec6972' } : {}}>

      </span>
    )
  }
}

class DashboardLearner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isErrorFound: false,
      open: false,
      no_data: false,
      profile: {},
      subjects: [],
      current_batch: null,
      batch_name: '',
      practices: [],
      courses: [],
      lecture_dpps: [],
      is_arrow: false,
      showModal: false,
      token: null,
      statetoken: localStorage.getItem("token"),
      //mode:['CLASSROOM',"WEBLINK","LIVE"],
      mode: [this.props.saveLanguageWiseData.classroom, this.props.saveLanguageWiseData.weblink, this.props.saveLanguageWiseData.live],
      categoryList: "",
      lcount: 0,
      name: localStorage.getItem("name"),
      user_id: localStorage.getItem("user_id"),
      username: localStorage.getItem("username"),
      userID: localStorage.getItem("user_id"),
      token: localStorage.getItem("token"),
      is_attendanceURL: false,
      currentLectureURL: '',
      centerID: localStorage.getItem("center_id"),
      batch_id: '',
      curriculumList: [],
      accordianOpen: false,
      allClasslists: [],
      allSubjectlists: [],
      allClasslistsCurrentLabel: '',
      allClasslistsCurrentid: '',
      allClasslistsCurrentDescription: '',
      semesterTabsActive: false,
      allClasslistsArchiveBatchId: ''



    }
    this.questionTimer = React.createRef();
  }

  componentDidMount() {


    //window.open('/learner/dashboard','_self')
    localStorage.setItem("page", "dashboard");
    this.getLearnerProfile();

    console.log("this.props.location?.state?");
    console.log(this.props.location?.state);
    let getBackCurrentClassId = this.props.location?.state?.currentClassId;
    console.log('getBackCurrentClassId', getBackCurrentClassId)

    console.log("this.props.saveLanguageWiseData")
    console.log(this.props?.saveLanguageWiseData)

    this.allClasslists();
    if (getBackCurrentClassId) {
      this.setState({ allClasslistsCurrentid: getBackCurrentClassId });
    } else {
      this.setState({ allClasslistsCurrentid: '' })
    }

    if (localStorage.getItem('lang')) {
      let language = localStorage.getItem('lang');
      console.log("language", language);
      this.fetchLanguageWiseData(language);
    }
  }
  fetchLanguageWiseData = async (language) => {
    const response = await this.props.getLanguageWiseData(language, "learner");
    if (response !== undefined && response.status == 1) {
      console.log("getLanguageWiseData");
      console.log(response?.data);
      localStorage.setItem('languageData', JSON.stringify(response?.data))
      this.setState({ mode: [this.props.saveLanguageWiseData.classroom, this.props.saveLanguageWiseData.weblink, this.props.saveLanguageWiseData.live] });


    }
  }


  allClasslists = async () => {
    const response = await this.props.getClassList();
    if (response) {
      console.log("response.data allClasslists");
      console.log(response.data);
      this.setState({ allClasslists: response.data });
      let currentBackCickData = response.data.filter(item => item.id == this.state.allClasslistsCurrentid)
      console.log("final Data");
      console.log(currentBackCickData);
      if (currentBackCickData.length) {
        this.getSemesterData(this.state.allClasslistsCurrentid, currentBackCickData[0].label, currentBackCickData[0].description, currentBackCickData[0].archived_batch_id)

      }

    }
  }

  getSemesterData = async (class_id, class_label, class_description, archived_batch_id) => {

    console.log("classs.id", class_id)
    console.log("classs.label", class_label)
    console.log("class_description", class_description)
    console.log("archived_batch_id", archived_batch_id)

    this.setState({ allClasslistsCurrentLabel: class_label });
    this.setState({ allClasslistsCurrentDescription: class_description });
    this.setState({ allClasslistsCurrentid: class_id });
    this.setState({ allClasslistsArchiveBatchId: archived_batch_id });
    this.setState({ semesterTabsActive: true });

    mixpanel.track("Select-Semester", {
      className: class_label,
      classId: class_id,
      archivedBatchId: archived_batch_id,
    })

    if (class_id) {
      const response = await this.props.getSubjectList(this.state.centerID, class_id);
      if (response) {
        console.log("response.data subjectList");
        console.log(response.data);
        this.setState({ allSubjectlists: response.data });

      }
    }
  }

  getCurrentSemesterData = () => {
    this.setState({ allClasslistsCurrentLabel: '' });
    this.setState({ allClasslistsCurrentDescription: '' });
    this.setState({ semesterTabsActive: false });
    this.setState({ allClasslistsCurrentid: '' });
    this.setState({ allClasslistsArchiveBatchId: '' });



  }





  show = (val) => this.setState({ open: true });

  close = () => this.setState({ open: false });

  showAttemptModal = () => this.setState({ showModal: true })

  closeAttemptModal = () => this.setState({ showModal: false })

  attemptPaper = (label) => {
    const contentPreview = this.props.contentPreview;
    if (label.toLowerCase() == 'review' || label.toLowerCase() == 'result') {
      this.props.history.push(
        `/learner/assessment-review/${contentPreview.content_id}`,
        { modal: true }
      )
    }
    else {
      this.setState({ showAttemptModal: false })
      this.props.history.push(
        `/learner/attempt/${contentPreview.content_id}`
      );
    }
  }

  attemptAndReview = async (label, content_id) => {
    this.setState({ content_id })
    if (label.toLowerCase() === 'review' || label.toLowerCase() === 'result') {
      this.props.history.push(
        `/learner/assessment-review/${content_id}`,
        { modal: true }
      )
    }
    else {
      await this.props.getAttemptPreview(content_id)
      this.setState({ showModal: !this.state.showModal })
    }
  }

  getLearnerProfile = async () => {
    const response = await this.props.getLearnerProfile();

    if (response.message == 'Network Error') {
      localStorage.setItem("is_error_found", 1)
      this.setState({ isErrorFound: true })
    } else {
      localStorage.setItem("is_error_found", 0)
      this.setState({
        profile: response.data, subjects: response.data.subjects, courses: response.data.batches,
        no_data: response.data.message == "You are not assigned to any class and section, Please contact Admin for assigning into class"
          ? true : false, current_batch: response.data.default_batch_id
      });
      this.getBatchName(response.data);
      this.getDashboard();
    }


    /*const clist=await this.props.getWpCategoryList();
    if(clist.message == "Network Error"){
      this.setState({isErrorFound:true})
    }else{
      this.setState({categoryList:clist?.resultData?.category});
    }*/


    let dashsubject = document.getElementsByClassName("dash-subject");

    if (dashsubject) {
      for (let i = 0; i < 3; i++) {
        if (dashsubject[i])
          dashsubject[i].style.display = "flex";
      }
    }


  }

  getBatchName = (val) => {
    if (val.batches && val.batches.length) {
      for (let i = 0; i < val.batches.length; i++) {
        if (val.default_batch_id === val.batches[i].id) {
          this.setState({ batch_name: val.batches[i].program.label });
          this.setState({ batch_id: val.batches[i].id });
        }
      }

    }
    this.curriculumList();
  }

  curriculumList = async () => {
    const { current_batch } = this.state
    console.log("current_batch")
    console.log(current_batch)
    if (current_batch) {
      const response = await this.props.getCurriculumListing(current_batch);
      if (response !== undefined) {
        console.log("response status");
        console.log(response.data);
        this.setState({ curriculumList: response?.data })
      }
    }

  }

  getDashboard = async () => {
    const response = await this.props.getStudentDashboard();

    if (response.message == "Network Error") {
      localStorage.setItem("is_error_found", 1)
      this.setState({ isErrorFound: true })
    } else {
      localStorage.setItem("is_error_found", 0)
      if (response)
        this.setState({
          lecture_dpps: response.data.lecture_dpps,
          practices: response.data.released_exercises, loading: false
        });
    }
  }

  getTime = (val) => {
    var date = new Date(val);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12;
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var timewithampm = hours + ':' + minutes + ' ' + ampm;
    return timewithampm.toUpperCase();
  }

  switchCourse = async (val) => {
    await this.props.switchCourse(val);
    this.setState({ open: false });
  }
  /*convertedHour = (minutes) =>{    
    return Math.floor(minutes / 60);
  }*/

  listCount = () => {
    this.setState({ lcount: this.state.lcount + 1 })
  }

  getwpUserToken = async (page, link) => {
    const response = await this.props.getwpUserToken();
    this.setState({ token: response.data ? response.data.token : null });

    window.open(link, "_blank");
    //window.location.href=`http://sow.learningoxygen.com/1.php?username=${this.state.profile.username}&page=${page}&token=${response.data?response.data.token:null}`;
    //window.open(`http://sow.learningoxygen.com/1.php?username=${this.state.profile.username}&page=${page}&token=${response.data?response.data.token:null}`, "_blank");
  }

  getScore = (val) => {
    return !isEmpty(val) ? val.score.value : 0;
  }

  getMode = (val) => {
    console.log("mode");
    console.log(this.state.mode);
    return this.state.mode[val];
  }

  convertedHour = (minutes) => {
    return minutes >= 60 ? Math.round(minutes / 60 * 100) / 100 + this.props.saveLanguageWiseData.hrs : minutes + this.props.saveLanguageWiseData.mins;
  }

  saveQuestionTime = (time_taken, difference) => {
    //console.log(Math.floor(time_taken/(1000*60)))
    /*setInterval(function() {
      if(Math.floor(time_taken/(1000*60)) * 60 * 1000 == 10 * 60 * 1000) window.location.reload();
    }, 60000);  */
    if (Math.floor(time_taken / (1000 * 60)) * 60 * 1000 == 10 * 60 * 1000) {
      setTimeout(function () { window.location.reload(true) }, 60000);
    }
    //
  }

  seeMore = () => {
    let dashsubject = document.getElementsByClassName("dash-subject");

    if (dashsubject) {
      for (let i = 3; i <= dashsubject.length; i++) {
        if (dashsubject[i])
          dashsubject[i].style.display = "flex";
      }

      this.setState({ is_arrow: true })
    }
  }
  seeLess = () => {
    let dashsubject = document.getElementsByClassName("dash-subject");

    if (dashsubject) {
      for (let i = 3; i <= dashsubject.length; i++) {
        if (dashsubject[i])
          dashsubject[i].style.display = "none";
      }

      this.setState({ is_arrow: false })
    }
  }

  bbbJoinLiveClass = async (etc, session) => {
    const { name, user_id } = this.state

    if (etc) {
      let etcObject = JSON.parse(etc);

      const response = await this.props.bbbJoinLiveClass(session, name, etcObject.attendantPasswordBBB, user_id)
      console.log(response)
      if (response != undefined) {
        window.open(response.data?.liveClassUrl, '_blank').focus();
      }
    }
  }
  zoomAttendance = (lectureURL, ids) => {
    let { id, userID, currentLectureURL } = this.state;
    this.setState({ currentLectureURL: lectureURL });
    var today = new Date();
    var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    var dateTime = date + ' ' + time;
    console.log("lectureURL", lectureURL)
    console.log("id", ids)
    console.log("userID", localStorage.getItem("user_id"))
    console.log("dateTime", dateTime)
    console.log("currentLectureURL", lectureURL)
    if (ids, userID, dateTime) {
      console.log("enter call")
      this.zoomAttendanceAPI(ids, userID, dateTime);
    }

  }


  // zoomAttendanceAPI = async (lecture_id,user_id,join_time) =>{
  //   const response = await this.props.callzoomAttendanceAPI(lecture_id,user_id,join_time);

  //     this.setState({is_attendanceURL:false});
  //     console.log("attendanceURL", this.state.is_attendanceURL);
  //     console.log("currentLectureURL", this.state.currentLectureURL);
  //     window.open(this.state.currentLectureURL,"_blank");

  //  }

  zoomAttendanceAPI = async (lecture_id, user_id, join_time) => {
    console.log("zoomAttendanceAPI call")
    const response = await this.props.callzoomAttendanceAPI(lecture_id, user_id, join_time, localStorage.getItem('viewrecordingButtoncLick') ? "recording" : 'attendance');

    this.setState({ is_attendanceURL: false });
    console.log("attendanceURL", this.state.is_attendanceURL);
    console.log("currentLectureURL", this.state.currentLectureURL);
    console.log("Response status", response?.status);
    console.log("response.url", response?.data?.url);
    localStorage.removeItem('viewrecordingButtoncLick', true)
    if (response !== undefined || response?.status == 1) {
      //window.open(response.data.url,"_blank");
    }
  }



  onResourceLoad = () => {
    window.location.reload();
  }

  getFinalUrl = async (content_id) => {
    this.attemptAndReview('attempt', content_id);
  }

  accrodianFunction = () => {
    this.setState((prevState) => ({
      accordianOpen: !prevState.accordianOpen
    }))
  }



  viewRecodingClick = (lectureURL, ids) => {
    localStorage.setItem('viewrecordingButtoncLick', true);
    this.zoomAttendance(lectureURL, ids)
  }

  render() {
    mixpanel.init('6f29577c37a87900c42872e181406747', {
      debug: true

    });

    if (this.props.loading || this.state.loading || this.state.no_data) {
      return (
        <div className="app-body">
          <div className="app-div">
            {/* <img id="path-b" src={PathB} alt="pathb"/>
            <img id="path-d" src={PathD} alt="pathd"/> */}
            <div className="dashboard-main-learner">
              {this.state.no_data && <span id="no-data">
                {this.props.saveLanguageWiseData.class_not_mapped}
              </span>}
              <Empty />
            </div>
          </div>
        </div>
      )
    }
    return (
      <>

        <Popup open={this.state.open} closeOnDocumentClick onClose={this.close}
          className="popup-content2 modelBanner-content2">
          <div className="courses-popup modelBanner">
            {/* <span>Switch Course</span> */}
            {/* <Carousel containerClass="courses-popup-tray" responsive={responsive}
              keyBoardControl={false} swipeable={false} draggable={false}
              removeArrowOnDeviceType={["tablet", "mobile"]}>
              {this.state.courses.map(course => 
                <div className="course-div" key={course.id} 
                  onClick={() => this.switchCourse(course.id)}>
                  <div style={course.id === this.state.current_batch 
                    ? {backgroundColor: '#B0E1E5'} : {}}>
                    <img src={CourseImg} alt="course"/>
                  </div>
                  <div>
                    {course.program.label}
                  </div>
                </div>
              )}
            </Carousel> */}
            <img src={modelBanner} />
          </div>
        </Popup>
        {!isEmpty(this.props.contentPreview)
          && <Popup className="attempt-modal" open={this.state.showModal} closeOnDocumentClick
            onClose={this.closeAttemptModal}>
            <AssessmentPreview
              contentPreview={this.props.contentPreview}
              attempt={this.attemptPaper}
              showAttemptButton={true}
              saveLanguageWiseData={this.props.saveLanguageWiseData}
            />
          </Popup>}

        <Popup className="iserrorfound" open={false}
          onClose={this.closeAttemptModal}>
          <div><img src={OOPS} alt="oops" width="200px" height="124px" /></div>
          <div className="ohno">Oh no</div>
          <div className="connection_failed">{this.props.saveLanguageWiseData.poor_connection}</div>
          <div style={{ marginTop: "20px" }}>
            <button onClick={this.onResourceLoad}>{this.props.saveLanguageWiseData.tryagain}</button>
          </div>
        </Popup>

        <div className="app-body">
          <ReactNotifications />
          <div className={this.props.dashboard_type == 'dynamic' ? "app-div widget-app-dashboard" : "app-div app-dashboard"}>
            <Helmet>
              <title>Dashboard - Learner</title>
            </Helmet>
            {/* <img id="path-b" src={PathB} alt="pathb"/>
            <img id="path-d" src={PathD} alt="pathd"/> */}
            <div className="dashboard-main">
              <div className="dashboard-left">
                {/* {JSON.stringify(this.state.allClasslists.length)} */}
                <div className='semesterTabsWrapper'>
                  <a href="app/mycoach"><img src={BackArrow} /></a>
                  <ul className="nav">
                    {this.state.allClasslists && this.state.allClasslists.map((classes) =>
                      <li className={this.state.allClasslistsCurrentid == classes.id ? 'tabs active' : "tabs"} id={classes.id} onClick={() => this.getSemesterData(classes.id, classes.label, classes.description, classes.archived_batch_id)}>{classes.label}</li>
                    )}
                    {this.state.allClasslists.length > 0 &&
                      <li className={!this.state.allClasslistsCurrentid ? "tabs active" : "tabs"} onClick={() => this.getCurrentSemesterData()}>Current Semester</li>
                    }
                  </ul>
                </div>


                {/*<div className="all-courses">
                  <a className="goto-course" onClick={() => this.getwpUserToken('testprep')}>
                    <div className="course-image">
                      <div className="course-image-div">  
                        <img src={TestPrep} alt="img"/>
                      </div>
                    </div>
                    <span>
                      Test Prep Courses
                    </span>
                  </a>
                  <a className="goto-course" onClick={() => this.getwpUserToken('learncode')}>
                    <div className="course-image">
                      <div className="course-image-div">  
                        <img src={Coding} alt="img"/>
                      </div>
                    </div>
                    <span>
                      Learning Code
                    </span>
                  </a>
                   <a className="goto-course" onClick={() => this.getwpUserToken('schoolcode')}>
                    <div className="course-image"> 
                      <div className="course-image-div">  
                        <img src={CodeLab} alt="img"/>
                      </div>
                    </div>
                    <span>
                      School Coding Lab
                    </span>
                  </a> 
                </div>*/}

                {/* <div className="banner-section">
                  <img src={mainBanner}/>
                </div> */}
                <div className="topHeading">
                  {this.state.allClasslistsCurrentLabel &&
                    <h3>{this.state.allClasslistsCurrentLabel}</h3>
                  }
                  {!this.state.allClasslistsCurrentLabel &&
                    <h3>{this.state.profile.classs?.label}</h3>
                  }
                </div>
                <div className='welcomeheading'>
                  {this.state.allClasslistsCurrentDescription && this.state.allClasslistsCurrentDescription !== 'null' &&
                    this.state.allClasslistsCurrentDescription
                  }
                  {!this.state.allClasslistsCurrentDescription &&
                    this.state.profile.classs?.description
                  }
                </div>
                {/* {this.state.courses.length > 0 ? <div className='welcomeheading'>You have one subscribed course <span>{this.state.batch_name}</span></div> : '' } */}

                {this.state.courses.length > 0 && <div className="dashboard-exercise" style={{ display: "none" }}>
                  <h1>{this.props.saveLanguageWiseData.my_courses}</h1>
                  <div className="dashboard-practices-course">
                    {this.state.courses.map(course =>
                      <div className="course-div" key={course.id}>
                        <div style={course.id === this.state.current_batch
                          ? { backgroundColor: 'var(--primary-rgba)' } : {}}>
                          <img src={CourseImg} alt="course" />
                        </div>
                        <div>
                          {course.program.label}
                        </div>
                      </div>
                    )}
                  </div>
                </div>}

                <h1>Subjects</h1>
                {/* <h1>{this.props.saveLanguageWiseData?.your_activities}</h1> */}
                <div className="dashboard-subjects">
                  {!this.state.allClasslistsCurrentLabel && this.state.subjects.map((subject, index) =>
                    <Link className="dash-subject" style={index > 2 && this.props.learnerprofile?.institute_settings.is_dashboard == "true" ? { display: "none" } : ""} id={"dash_" + index} key={subject.id}
                      to={{
                        pathname: `/learner/study-material/${subject.id}`,
                        state: { subject_label: subject.label },
                      
                      }}
                      style={{ backgroundColor: subject.background_code }}>
                      {/* onClick={() => mixpanel.track("Subject", {
                        subjectId: subject.id,
                        subjectLabel: subject.label,
                        attendancePercentage: subject.attendance_percentage

                      })} */}
                      <div className='attendancePercentage'><i>attendance {subject.attendance_percentage}%</i><span style={{ width: `${subject.attendance_percentage}%` }}></span></div>
                      <div>
                        <img src={common.addFileUrl(subject.url)} alt="sub" />
                      </div>
                      <span>{subject.label}</span>
                    </Link>
                  )}

                  {this.state.allClasslistsCurrentLabel && this.state.allSubjectlists.map((subject, index) =>
                    <Link className="dash-subject" style={index > 2 && this.props.learnerprofile?.institute_settings.is_dashboard == "true" ? { display: "none" } : ""} key={subject.id}
                      to={{
                        pathname: `/learner/study-material/${subject.id}`,
                        state: { subject_label: subject.label, is_archive: true, currentClassid: this.state.allClasslistsCurrentid }
                      }}
                      style={{ backgroundColor: subject.background_code }}>

                      <div>
                        <img src={common.addFileUrl(subject.image_url)} alt="sub" />
                      </div>
                      <span>{subject.label}</span>
                    </Link>
                  )}
                </div>

                {/*this.props.learnerprofile?.institute_settings.is_dashboard == "true" ? <>!this.state.is_arrow ? <span className="dashboard-list-downarrow" onClick={this.seeMore} style={this.state.subjects.length <=3 ?{display:"none"}:''}><i className="fa fa-chevron-down"></i></span> : <span className="dashboard-list-downarrow" onClick={this.seeLess} style={this.state.subjects.length <=3 ?{display:"none"}:''}><i className="fa fa-chevron-up"></i></span></>:<></>*/}

                <div className="dashboard-assignment-list">
                  {!this.state.allClasslistsArchiveBatchId && this.props.logindetails.institute_settings.dashboard_setting && JSON.parse(this.props.logindetails.institute_settings.dashboard_setting).assignment ? <a href="/learner/user-assignments-list">
                    <div className="arrow"><img src={arrowicon} /></div>
                    <div className="icon"><img src={Assignment} /></div>
                    <div className="content">
                      <h3>{this.props.saveLanguageWiseData?.assignment}</h3>
                    </div>
                  </a> : ""}
                  {this.state.allClasslistsArchiveBatchId &&
                    <Link
                      // to={{ pathname: `/learner/assignment`,
                      to={{
                        pathname: `/learner/user-assignments-list`,
                        state: { archiveBatchID: this.state.allClasslistsArchiveBatchId, currentClassid: this.state.allClasslistsCurrentid }
                      }}>
                      <div className="arrow"><img src={arrowicon} /></div>
                      <div className="icon"><img src={Assignment} /></div>
                      <div className="content">
                        <h3>{this.props.saveLanguageWiseData?.assignment}</h3>
                      </div>
                    </Link>
                  }

                  {/* {this.state.allClasslistsArchiveBatchId} */}

                  {!this.state.allClasslistsArchiveBatchId && this.props.logindetails.institute_settings.dashboard_setting && JSON.parse(this.props.logindetails.institute_settings.dashboard_setting).assessment ? <a href="/learner/user-assessments-list">
                    <div className="arrow"><img src={arrowicon} /></div>
                    <div className="icon"><img src={Assessment} /></div>
                    <div className="content">
                      <h3>{this.props.saveLanguageWiseData?.assessments}</h3>
                    </div>
                  </a> : ""}

                  {this.state.allClasslistsArchiveBatchId &&
                    <Link
                      // to={{ pathname: `/learner/tests`,
                      to={{
                        pathname: `/learner//user-assessments-list`,
                        state: { archiveBatchID: this.state.allClasslistsArchiveBatchId, currentClassid: this.state.allClasslistsCurrentid }
                      }}>
                      <div className="arrow"><img src={arrowicon} /></div>
                      <div className="icon"><img src={Assessment} /></div>
                      <div className="content">
                        <h3>{this.props.saveLanguageWiseData?.assessments}</h3>
                      </div>
                    </Link>
                  }



                  {this.props.logindetails.institute_settings.dashboard_setting && JSON.parse(this.props.logindetails.institute_settings.dashboard_setting).project ? <a href="/learner/project-listing">
                    <div className="arrow"><img src={arrowicon} /></div>
                    <div className="icon"><img src={StudioProjectIcon} /></div>
                    <div className="content">
                      <h3>{this.props.saveLanguageWiseData.studio_project}</h3>
                    </div>
                  </a> : ""}
                </div>
                {/* {this.state.centerID == 5 &&
                <div className='finalAssessmentBox'>
                    <a onClick={() => this.getFinalUrl(2075)}>
                      <div className="arrow"><img src={arrowicon} /></div>
                      <div className="icon"><img src={Assignment} /></div>
                      <div className="content">
                        <h3>Final Post Module Assessment</h3>
                        <div className='button'><p>Attempt Now</p></div>
                      </div>
                    </a>
                  </div>  
                  } */}

                {this.state.practices.length > 0 &&
                  <div className="dashboard-exercise">
                    <Link to={`/learner/practice`}>
                      <h1>{this.props.saveLanguageWiseData.practice}</h1>
                      <img src={Right} alt="img" />
                    </Link>
                    <div className="dashboard-practices">
                      {this.state.practices.map((practice, index) =>
                        <div className="dash-practice" key={index}
                          id={practice.label === 'Attempt' ? "dash-practice1" : ""} style={practice.is_attempted ? { backgroundColor: '' } : { backgroundColor: "var(--primary-rgba)" }}>

                          <div>
                            <div Style={`background-image:url(${Target});width: 50px;height: 50px;background-repeat: no-repeat;background-size: contain;order: 2;opacity:0.4`}></div>
                            <div Style="display: inline-block;">
                              <span className="dash-flex2-title-1">{practice.title}</span>
                              <span Style="font-size: 13px;">
                                <span Style="color:#a9a0a0">{this.props.saveLanguageWiseData.score} :</span><span style={{ color: "var(--primary)" }}>{this.getScore(practice.scorecard)}</span>
                              </span>
                            </div>
                          </div>
                          <div>
                            {practice.actions.map(action =>
                              <a key={action.id}
                                onClick={() => this.attemptAndReview(action.label, practice.content_id)}>
                                <button style={practice.is_attempted ? { backgroundColor: "var(--primary)" } : { backgroundColor: "var(--primary)" }}>
                                  {action.label}
                                </button>
                              </a>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>}

                {/*this.state.categoryList && this.state.categoryList.length > 0 && <div style={{isplay:none}}>

                  {this.state.categoryList.map((list, index) =>{
                    return (
                      <div >
                        {!list.parent && <div className="dashboard-category">
                          <Link onClick={() => this.getwpUserToken('testprep',list.permalink)}>
                            <h1>{list.name}</h1>
                            <img src={Right} alt="img"/>
                          </Link>
                          <div className="dashboard-practices">
                            {this.state.categoryList.map((subtest, key) =>
                                
                                <div style={list.id != subtest.parent ?{display:"none"}:{display:"block"}} className="category-list">
                                {list.id == subtest.parent && 
                                  <a className="goto-course-list" href={subtest.permalink} target="_blank">
                                    
                                    <div className="course-image">
                                      <div className="course-image-div">  
                                        <img src={subtest.image} alt="img"/>
                                      </div>
                                    </div>
                                    <span>
                                      {subtest.name}
                                    </span>
                                  </a>
                                }
                              </div>
                            )}
                          </div>
                          </div>
                        }
                      </div>
                    );
                  })}
                  </div>*/}

                <div className="dashboard-schedule newDesign">
                  <div className="dash-sch-head">
                    <div className="viewall-btn"><a href="/learner/sessions">{this.props.saveLanguageWiseData.view_all}</a></div>
                  </div>
                  {/* <div className="dash-sch-head">
                  
                    <div>
                      <span>Upcoming Activities</span>
                    </div>
                  </div> */}
                  {this.state.lecture_dpps.map(lecture => {
                    if (lecture.id !== undefined) {
                      return <div className="dash-lecture newDesign" key={lecture.id}>
                        {lecture.start_date_time && <StartTimer startTime={lecture.start_date_time} onChange={this.saveQuestionTime}
                          count={0} ref={this.questionTimer} />}
                        <div className="dash-lecture-date">
                          <div>{(new Date(lecture.start_date_time)).getDate()}</div>
                          <span>{MonthTable[(new Date(lecture.start_date_time)).getMonth() + 1]}</span>
                          {this.getTime(lecture.start_date_time)}
                        </div>
                        <div className="newDesign">
                          <div className="actions">
                            <div className="testType">
                              {lecture.type === 'Lecture' ? <span>{this.getMode(lecture.mode)}</span> : null}
                            </div>
                          </div>
                          <div className="dash-lecture-date-title">
                            <div className="dash-lec-title">{lecture.title}</div>
                            <div className="newDesign">
                              <div className="dash-lec-title-hrs">{this.props.saveLanguageWiseData.duration} -{lecture.duration_hrs} {this.props.saveLanguageWiseData.hrs}</div>
                              <div className="dash-lec-title-hrs">{lecture.faculty ? "By" + " " + lecture.faculty : ""}</div>
                              {lecture.mode == 0 && <div className="dash-lec-title-hrs">{this.props.saveLanguageWiseData.location} -{lecture.url}</div>}
                            </div>
                          </div>

                          <div className="dash-lecture-date-third">
                            {lecture.action_label != "Join Class" &&
                              <span><a onClick={() => this.viewRecodingClick(lecture.record_url, lecture.id)} href={lecture.record_url} target="_blank">{lecture.action_label != "Join Class" && lecture?.action_label}</a></span>
                            }
                            {/* <div> 
                            {lecture.type === 'Lecture' ? <span>{this.getMode(lecture.mode)}</span> : null}
                          </div> */}
                            <div Style={lecture.action_label && lecture.action_label === "Join Class" ? "display:flex" : "display:none"}>
                              {lecture.action_label !== null && lecture.action_label !== '' && lecture.action_label
                                !== undefined && lecture.action_label === "Join Class" && lecture.liveClass_server !== 3
                                ? <a href={lecture.open_url}>
                                  {lecture.action_label}
                                </a> : lecture.action_label !== null && lecture.action_label !== '' && lecture.action_label
                                  !== undefined && lecture.action_label === "Join Class" && lecture.liveClass_server === 3
                                  ? <a href="#" onClick={() => this.zoomAttendance(lecture.open_url, lecture.id)}>{lecture.action_label}</a> : null}

                            </div>
                          </div>


                        </div>
                      </div>
                    }
                    else {
                      return <div className="dash-lecture newDesign" key={lecture.content_id}>
                        <div className="dash-lecture-date">
                          <div>{(new Date(lecture.due_date)).getDate()}</div>
                          <span>{MonthTable[(new Date(lecture.due_date)).getMonth() + 1]}</span>
                          {this.getTime(lecture.due_date)}
                        </div>
                        <div className="newDesign">
                          <div className="actions">
                            <div className="testType">
                              {lecture.type === 'DPPPlanner' || lecture.type === 'UserAttemptCount' ? <span>{this.props.saveLanguageWiseData.real_test}</span> : null}
                            </div>
                          </div>
                          <div className="dash-lecture-date-title">
                            <div className="left">
                              <div className="dash-lec-title">{lecture.title}</div>
                              <div className="newDesign">
                                <div className="dash-lec-title-hrs">{this.convertedHour(lecture.duration_mins)}</div>
                                <div className="dash-lec-title-hrs">{lecture.faculty ? this.props.saveLanguageWiseData.by + " " + lecture.faculty : ""}</div>
                                {lecture.time_left && <span className="dash-lec-hrs">{this.props.saveLanguageWiseData.time} - {lecture.time_left}</span>}
                              </div>
                              {lecture.mode == 0 &&
                                <div className="newDesign">
                                  <div className="dash-lec-title-hrs">{this.props.saveLanguageWiseData.location} -{lecture.url}</div>
                                </div>
                              }
                            </div>

                            <div className="dash-lecture-date-third">
                              {/* <div Style="background-color:#fff6e4;font-family: lato;"> 
                            {lecture.type === 'DPPPlanner' ? <span>REAL TEST</span> : null}
                          </div> */}
                              <div Style={lecture.actions.length == 0 ? "display:none" : "display:flex"}>
                                {lecture.actions.map(action =>
                                  <a index={action.id}
                                    onClick={() => this.attemptAndReview(action.label, lecture.content_id)}>
                                    {action.label}
                                  </a>

                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  })}

                </div>


                <div className="dashboard-category" style={{ display: "none" }}>

                  <Link to="/#" onClick={() => this.getwpUserToken('testprep')} className="careerBoosterLink">
                    <h1>{this.props.saveLanguageWiseData.career_booster}</h1>
                    <img src={Right} alt="img" />
                  </Link>

                  <div className="dashboard-practices">
                    <div style={{ width: "200px", marginRight: "20px", marginBottom: "16px", boxShadow: "0 8px 18px 0 rgba(72, 145, 152, 0.2)" }}>
                      <a className="goto-course-list" href="https://schoolsonweb.com/career-listing/" target="_blank">
                        <div className="course-image">
                          <div className="course-image-div">
                            <img src={Frame10} alt="img" />
                          </div>
                        </div>
                        <span>
                          {this.props.saveLanguageWiseData.explore_career}
                        </span>
                      </a>
                    </div>
                    <div style={{ width: "200px", marginRight: "20px", marginBottom: "16px", boxShadow: "0 8px 18px 0 rgba(72, 145, 152, 0.2)" }}>
                      <a className="goto-course-list" href="https://schoolsonweb.com/opportunity-listing/" target="_blank">
                        <div className="course-image">
                          <div className="course-image-div">
                            <img src={Frame11} alt="img" />
                          </div>
                        </div>
                        <span>
                          {this.props.saveLanguageWiseData.explore_Opportunities}
                        </span>
                      </a>
                    </div>
                    <div style={{ width: "200px", marginRight: "20px", marginBottom: "16px", boxShadow: "0 8px 18px 0 rgba(72, 145, 152, 0.2)" }}>
                      <a className="goto-course-list" href="https://schoolsonweb.com/scholarships/" target="_blank">
                        <div className="course-image">
                          <div className="course-image-div">
                            <img src={Frame12} alt="img" />
                          </div>
                        </div>
                        <span>
                          {this.props.saveLanguageWiseData.explore_scholarship}
                        </span>
                      </a>
                    </div>

                    {/*<div Style="width:200px;margin-right: 20px,margin-bottom: 16px,box-shadow: 0 8px 18px 0 rgba(72, 145, 152, 0.2);">
                      <a className="goto-course-list" href="#" target="_blank">
                        <div className="course-image">
                          <div className="course-image-div">  
                            <img src={Frame8} alt="img"/>
                          </div>
                        </div>
                        <span>
                          Explore Exams
                        </span>
                      </a>                          
                    </div>*}
                    {/*<div Style="width:200px;margin-right: 20px,margin-bottom: 16px,box-shadow: 0 8px 18px 0 rgba(72, 145, 152, 0.2);">
                      <a className="goto-course-list" href="#" target="_blank">
                        <div className="course-image">
                          <div className="course-image-div">  
                            <img src={Frame9} alt="img"/>
                          </div>
                        </div>
                        <span>
                          Explore Free Resources
                        </span>
                      </a>                          
                    </div>*/}
                  </div>
                </div>
              </div>
              <div className="dashboard-right">
                <div className="dashboard-profile" style={{ display: "none" }}>
                  <div className="dash-prof-img" style={{ display: "none" }}>
                    {this.state.profile?.profile_picture === ''
                      || this.state.profile?.profile_picture === null
                      ? <img src={Profile} alt="img" />
                      : <div className="dash-prof-img-in" style={{
                        backgroundImage:
                          `url(${common.addFileUrl(this.state.profile?.profile_picture)})`
                      }}>
                      </div>}
                  </div>
                  <div className="dash-prof-name" style={{ display: "none" }}>{this.state.profile?.name}</div>
                  {/* <div className="drop-profile-creds" onClick={this.show}>
                    <div>{this.state.batch_name}</div>
                  </div> */}

                  <div className="profile-email" style={{ display: "none" }}>
                    <div>{this.state.profile?.mobile}</div>
                    <div>{this.state.profile?.email}</div>
                  </div>
                  {/*<hr Style="border: 1px solid #72c1be; !important;width: 34px;"/>*/}
                  <div className="profile-social-icon" style={{ display: "none" }}>
                    <Link to='/#'><img src={facebookIcon} /></Link>
                    <Link to='/#'><img src={linkedinIcon} /></Link>
                    <Link to='/#'><img src={instagramIcon} /></Link>
                  </div>
                </div>

                <div className="dashboard-schedule newDesign marginBottom">
                  {/* {this.props.saveLanguageWiseData} */}
                  <div className="dashboard-title" className={this.state.accordianOpen ? 'dashboard-title open' : 'dashboard-title'} onClick={this.accrodianFunction}>
                    <div className="drop-profile-creds">
                      {this.props.saveLanguageWiseData.curriculum}
                      <span></span>
                      <img src={AccordianArrow} />
                    </div>
                  </div>
                  {this.state.curriculumList && this.state.curriculumList.length > 0 &&
                    <ol>
                      {this.state.curriculumList.map(listData =>
                        <li key={listData.id}>
                          <div>
                            <h5>{listData.title}</h5>
                            <p>{listData.description}</p>
                          </div>

                          <a href={listData.file} target={listData.file.substring(listData.file.lastIndexOf(".")) == ".pdf" ? "_blank" : ''} download>{this.props.saveLanguageWiseData.download}</a>
                        </li>
                      )}
                    </ol>
                  }
                  {this.state.curriculumList && this.state.curriculumList.length == 0 && <div className='nocurriculumfound'>{this.props.saveLanguageWiseData.no_curriculum_found}</div>}
                </div>

                {this.props.logindetails.institute_settings.dashboard_setting && JSON.parse(this.props.logindetails.institute_settings.dashboard_setting).course_block ?
                  <div className="dashboard-schedule newDesign">

                    <div className="dashboard-title">
                      <div className="drop-profile-creds">
                        {this.props.saveLanguageWiseData.course_schedule}
                        <span></span>
                      </div>
                      <div className="viewall-btn"><a href="/learner/sessions">{this.props.saveLanguageWiseData.view_all}</a></div>
                    </div>

                    {/* <div className="dash-sch-head">
                    <div>
                      <span>Upcoming Activities</span>
                    </div>
                  </div> */}
                    {this.state.lecture_dpps.map(lecture => {
                      if (lecture.id !== undefined) {
                        return <div className="dash-lecture newDesign" key={lecture.id}>
                          {lecture.start_date_time && <StartTimer startTime={lecture.start_date_time} onChange={this.saveQuestionTime}
                            count={0} ref={this.questionTimer} />}
                          <div className="dash-lecture-date">
                            <div>{(new Date(lecture.start_date_time)).getDate()}</div>
                            <span>{MonthTable[(new Date(lecture.start_date_time)).getMonth() + 1]}</span>
                            {this.getTime(lecture.start_date_time)}
                          </div>
                          <div className="newDesign">
                            <div className="actions">
                              <div className="testType">
                                {lecture.type === 'Lecture' ? <span>{this.getMode(lecture.mode)}</span> : null}
                              </div>
                            </div>
                            <div className="dash-lecture-date-title">
                              <div className="dash-lec-title">{lecture.title}</div>
                              <div className="newDesign">
                                <div className="dash-lec-title-hrs">{lecture.duration_hrs} {this.props.saveLanguageWiseData.hrs}</div>
                                <div className="dash-lec-title-hrs">{lecture.faculty ? this.props.saveLanguageWiseData.by + " " + lecture.faculty : ""}</div>
                                {lecture.mode == 0 && <div className="dash-lec-title-hrs">{lecture.url}</div>}
                              </div>
                            </div>

                            <div className="dash-lecture-date-third ffff">
                              {lecture.action_label != "Join Class" && lecture.record_url &&
                                <span>
                                  {/* <a title="VIEW RECORDING" onClick={() => this.viewRecodingClick(lecture.record_url,lecture.id)}  href={lecture.record_url} target="_blank">{lecture.action_label != "Join Class" && lecture?.action_label}</a> */}
                                  {/* <Link  onClick={() => this.viewRecodingClick(lecture.record_url, lecture.id)} target="_blank" to={'/learner/view-recording/'+lecture.id}>
                                 {lecture.action_label}
                               </Link> */}

                                  <Link>
                                    {lecture.action_label}
                                  </Link>
                                </span>
                              }
                              {lecture.action_label != "Join Class" && !lecture.record_url &&
                                <span>{lecture.action_label}</span>
                              }
                              {/* <div> 
                            {lecture.type === 'Lecture' ? <span>{this.getMode(lecture.mode)}</span> : null}
                          </div> */}
                              <div Style={lecture.action_label && lecture.action_label === "Join Class" ? "display:flex" : "display:none"}>
                                {lecture.action_label !== null && lecture.action_label !== '' && lecture.action_label
                                  !== undefined && lecture.action_label === "Join Class" && lecture.liveClass_server !== 3
                                  ? <a href={lecture.url} title="JOIN CLASSnnnnnnnnnn" target='_blank' onClick={() => this.zoomAttendance(lecture.open_url, lecture.id)}>
                                    {lecture.action_label}
                                  </a> : lecture.action_label !== null && lecture.action_label !== '' && lecture.action_label
                                    !== undefined && lecture.action_label === "Join Class" && lecture.liveClass_server === 3
                                    ? <a href={lecture.open_url} target='_blank' title="JOIN CLASS" onClick={() => this.zoomAttendance(lecture.open_url, lecture.id)} >{lecture.action_label}</a> : null}

                              </div>
                            </div>
                          </div>
                        </div>
                      }
                      else {
                        return <div className="dash-lecture newDesign" key={lecture.content_id}>
                          <div className="dash-lecture-date">
                            <div>{(new Date(lecture.due_date)).getDate()}</div>
                            <span>{MonthTable[(new Date(lecture.due_date)).getMonth() + 1]}</span>
                            {this.getTime(lecture.due_date)}
                          </div>
                          <div className="newDesign">
                            <div className="actions">
                              <div className="testType">
                                {lecture.type === 'DPPPlanner' || lecture.type === 'UserAttemptCount' ? <span>{this.props.saveLanguageWiseData.real_test}</span> : null}
                              </div>
                            </div>
                            <div className="dash-lecture-date-title">
                              <div className="left">
                                <div className="dash-lec-title">{lecture.title}</div>
                                <div className="newDesign">
                                  <div className="dash-lec-title-hrs">{this.convertedHour(lecture.duration_mins)}</div>
                                  <div className="dash-lec-title-hrs">{lecture.faculty ? this.props.saveLanguageWiseData.by + " " + lecture.faculty : ""}</div>
                                  {lecture.time_left && <span className="dash-lec-hrs">{this.props.saveLanguageWiseData.time} - {lecture.time_left}</span>}
                                </div>
                              </div>

                              <div className="dash-lecture-date-third">
                                {/* <div Style="background-color:#fff6e4;font-family: lato;"> 
                            {lecture.type === 'DPPPlanner' ? <span>REAL TEST</span> : null}
                          </div> */}
                                <div Style={lecture.actions.length == 0 ? "display:none" : "display:flex"}>
                                  {lecture.actions.map(action =>
                                    <a index={action.id}
                                      onClick={() => this.attemptAndReview(action.label, lecture.content_id)}>
                                      {action.label}
                                    </a>

                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      }
                    })}

                  </div> : ""}
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    contentPreview: state.classroomReducer.contentPreview,
    learnerprofile: state.commonReducer.logindetails,
    dashboard_type: state.commonReducer.dashboard_type,
    logindetails: state.commonReducer.logindetails,
    saveLanguageWiseData: state.commonReducer.languageData
  }
}

const mapDispatchToProps = (dispatch) => {
  const { getStudentDashboard, getLearnerProfile, switchCourse, getwpUserToken,
    getAttemptPreview, getWpCategoryList, bbbJoinLiveClass, callzoomAttendanceAPI, getCurriculumListing, getClassList, getSubjectList, getLanguageWiseData } = actions;
  return {
    getAttemptPreview: (contentId) => dispatch(getAttemptPreview(contentId)),
    getStudentDashboard: () => dispatch(getStudentDashboard()),
    getLearnerProfile: () => dispatch(getLearnerProfile()),
    switchCourse: (batch_id) => dispatch(switchCourse(batch_id)),
    getWpCategoryList: () => dispatch(getWpCategoryList()),
    getwpUserToken: () => dispatch(getwpUserToken()),
    bbbJoinLiveClass: (session, name, meetingPassword, userId) => dispatch(bbbJoinLiveClass(session, name, meetingPassword, userId)),
    getWpCategoryList: () => dispatch(getWpCategoryList()),
    callzoomAttendanceAPI: (lecture_id, user_id, join_time, join_type) => dispatch(callzoomAttendanceAPI(lecture_id, user_id, join_time, join_type)),
    getCurriculumListing: (batch_id) => dispatch(getCurriculumListing(batch_id)),
    getClassList: () => dispatch(getClassList()),
    getSubjectList: (center_id, class_id) => dispatch(getSubjectList(center_id, class_id)),
    getLanguageWiseData: (language, page) => dispatch(getLanguageWiseData(language, page)),



  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardLearner);