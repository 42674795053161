import { store } from 'react-notifications-component';
import { SET_CURRENT_PAGE, LOADING, LEARNER_PROFILE, PROFILE } from "../constants";

import Service from '../../utils/Service';
import common from '../../common';
import config from '../../config.js';
import Axios from 'axios';

const setCurrentPage = (currentPage) => dispatch => {
  dispatch({ type: SET_CURRENT_PAGE, currentPage })
}

const centerCreate = (name, email, mobile, brand_name, center_type) => dispatch => {
  const url = `${common.centerCreate}`
  Service.post(url, { name, email, mobile, brand_name, center_type }, (status, response) => {
    localStorage.setItem('token', response.data.token);
    localStorage.setItem('center_id', response.data.center_id);
    localStorage.setItem('preferredGrid', 'true');
    localStorage.setItem('is_admin', 'true');
    Service.updateToken();
    window.location.href = '/admin/institute';
  }).catch(er => {
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
}

const loginLog = async (username, password, loginStatus) => {
  return await Axios.post('https://vstageapi.learningoxygen.com/api/v1/auth/login-log',
    {
      "username": username,
      "password": password,
      "language": "en",
      "login_type": loginStatus
    }
  ).then((response) => {
    console.log(response);
  }).catch((error) => {
    console.log(error);
  });
};

const userLogin = (username, password, language, is_xp_signup) => dispatch => {
  const url = `${common.userLogin}`;
  Service.post(url, { username, password, language, is_xp_signup }, async (status, response) => {

    await loginLog(username, password, 'success');
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })


    // console.log(response);

    // console.log(response.data.dashboard_type)
    // return false;

    localStorage.setItem('logindetails', JSON.stringify(response.data));
    localStorage.setItem('token', response.data.auth_token);
    localStorage.setItem('name', response.data?.name);
    localStorage.setItem('preferredGrid', 'true');
    localStorage.setItem('user_id', response.data.id);
    localStorage.setItem('username', response.data.username);
    localStorage.setItem('dp', response.data.dashboard_type);
    localStorage.setItem('widget_alignment_size', response.data.widget_alignment.size);
    localStorage.setItem('widget_alignment_alignment', response.data.widget_alignment.alignment);


    if (response.data.center_details?.is_center_admin === true) {
      localStorage.setItem('is_admin', 'true');
      localStorage.setItem('username', username);
      localStorage.setItem('center_id', response.data.center_details?.center_id);
      Service.updateToken();
      window.location.href = '/admin/institute';
    }
    else if (response.data?.is_faculty === true) {

      localStorage.setItem('is_faculty', 'true');
      localStorage.setItem('username', username);
      localStorage.setItem('is_faculty_admin', "false")
      localStorage.setItem('center_id', response.data.center_details?.center_id);
      Service.updateToken();
      window.location.reload();
    }
    else {
      localStorage.setItem('is_faculty', 'false');
      localStorage.setItem('on_login', 1);
      localStorage.setItem('center_id', response.data.center_id);
      Service.updateToken();

      if (localStorage.getItem("gmail_source") == "1") {
        window.location.href = '/view-mapping' + localStorage.getItem("search");
      } else {
        window.location.reload();
      }

    }
  }).catch(async er => {

    await loginLog(username, password, 'failed');
    console.log("Error message" + er);
    let message = er.message == 'Network Error' ? "failed network connection" : er.message;

    store.addNotification({
      title: 'oops!!',
      message: `${message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })


  })
}

const userRegister = function (emailid) {

  //console.log("input mili" + emailid);
}

const getLearnerProfile = () => dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.userProfile}`;
  const response = Service.get(url, (status, response) => {
    dispatch({ type: LEARNER_PROFILE, learnerprofile: response.data });
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    return er
    /*localStorage.clear();
    window.location.href = config.logoutPage;*/
  })
  return response;
}

const getAdminProfile = () => dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.userProfile}`;
  const response = Service.get(url, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    //localStorage.clear();
    //window.location.href = config.logoutPage;
  })
  return response;
}

const getFacultyProfile = () => dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.facultyProfile}`;
  const response = Service.get(url, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    //localStorage.clear();
    //window.location.href = config.logoutPage;
  })
  return response;
}

const userLogout = () => dispatch => {
  const url = `${common.userLogout}`
  Service.post(url, {}, (status, response) => {
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
    let getCenterId = localStorage.getItem('center_id');
    let getUserRole = localStorage.getItem("is_admin");
    let is_faculty = localStorage.getItem("is_faculty");
    console.log("is_faculty", is_faculty)
    console.log("getCenterId");
    console.log(getCenterId);
    console.log(getUserRole);
    var gmail_source = localStorage.getItem('gmail_source');
    var search = localStorage.getItem('search');
    var pathname = localStorage.getItem('pathname');
    localStorage.clear();
    localStorage.setItem('gmail_source', gmail_source);
    localStorage.setItem('search', search);
    localStorage.setItem('pathname', pathname);

    if (getCenterId == 5 && !getUserRole && !is_faculty) {
      window.location.href = "https://sia.mic.gov.in";
    } else {
      let host = window.location.host;
      let finalhost = host;
      //window.location.href = config.logoutPage;
      window.location.href = window.location.protocol + "//" + finalhost;
      console.log(window.location.href);
    }

  }).catch(er => {
    console.log(er)
    let message = er.message == 'Network Error' ? "failed network connection" : er.message;
    store.addNotification({
      title: 'oops!!',
      message: `${message}`,
      type: 'warning',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
    let getCenterId = localStorage.getItem('center_id');
    let getUserRole = localStorage.getItem("is_admin");
    let is_faculty = localStorage.getItem("is_faculty");
    console.log("is_faculty", is_faculty)
    console.log("getCenterId");
    console.log(getCenterId);
    console.log(getUserRole);
    var gmail_source = localStorage.getItem('gmail_source');
    var search = localStorage.getItem('search');
    var pathname = localStorage.getItem('pathname');
    localStorage.clear();
    localStorage.setItem('gmail_source', gmail_source);
    localStorage.setItem('search', search);
    localStorage.setItem('pathname', pathname);

    if (getCenterId == 5 && !getUserRole && !is_faculty) {
      window.location.href = "https://sia.mic.gov.in";
    } else {
      let host = window.location.host;
      let finalhost = host;
      //window.location.href = config.logoutPage;
      window.location.href = window.location.protocol + "//" + finalhost;
      console.log(window.location.href);
    }
  })
}

export default {
  setCurrentPage,
  centerCreate,
  userLogin,
  userRegister,
  getLearnerProfile,
  getFacultyProfile,
  userLogout,
  getAdminProfile
}