import React, { useState, useRef, useEffect } from 'react'
import './CustomVideoPlayer.css'
import { connect } from 'react-redux';
import actions from '../../../redux/actions';
import _ from 'lodash';

const CustomVideoPlayer = ({ url, lecture_id, onError, updateRecordingVideoCompletion }) => {
  const videoRef = useRef(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const [volume, setVolume] = useState(1)
  const [currentTime, setCurrentTime] = useState(0)
  const [duration, setDuration] = useState(0)
  const [playbackRate, setPlaybackRate] = useState(1)
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [isControlsVisible, setIsControlsVisible] = useState(true)
  const [prevPercentage, setPrevPercentage] = useState(0);

  useEffect(() => {
    const video = videoRef.current
    if (!video) return

    const savedTime = localStorage.getItem(`videoProgress_${url}`)
    if (savedTime) {
      video.currentTime = parseFloat(savedTime)
    }

    const updateProgress = () => {
      console.log("video.currentTime");
      setCurrentTime(video.currentTime)

      localStorage.setItem(`videoProgress_${url}`, video.currentTime.toString())
    }

    video.addEventListener('timeupdate', updateProgress)
    video.addEventListener('loadedmetadata', () => setDuration(video.duration))

    return () => {
      video.removeEventListener('timeupdate', updateProgress)
    }
  }, [url])








  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.code === 'Space') {
        e.preventDefault()
        togglePlay()
      } else if (e.code === 'ArrowRight') {
        seek(currentTime + 10)
      } else if (e.code === 'ArrowLeft') {
        seek(currentTime - 10)
      }
    }
    const percentage = Math.floor((currentTime / duration) * 100);
    if(_.isEqual(prevPercentage, percentage)){
      return;
    }
    
    setPrevPercentage(percentage);

    updateRecordingVideoCompletion(lecture_id, percentage);

    document.addEventListener('keydown', handleKeyPress)
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    }
  }, [currentTime])

  const togglePlay = () => {
    const video = videoRef.current
    if (video) {
      if (isPlaying) {
        video.pause()
      } else {
        video.play()
      }
      setIsPlaying(!isPlaying)
    }
  }

  const stop = () => {
    const video = videoRef.current
    if (video) {
      video.pause()
      video.currentTime = 0
      setIsPlaying(false)
    }
  }

  const seek = (time) => {
    const video = videoRef.current
    if (video) {
      video.currentTime = time
      setCurrentTime(time)
    }
  }

  const changeVolume = (value) => {
    const video = videoRef.current
    if (video) {
      video.volume = value
      setVolume(value)
    }
  }

  const toggleMute = () => {
    const video = videoRef.current
    if (video) {
      video.muted = !video.muted
      setVolume(video.muted ? 0 : 1)
    }
  }

  const changePlaybackRate = (rate) => {
    const video = videoRef.current
    if (video) {
      video.playbackRate = rate
      setPlaybackRate(rate)
    }
  }

  const toggleFullScreen = () => {
    const videoContainer = document.querySelector('.video-container')
    if (!videoContainer) return

    if (!isFullScreen) {
      if (videoContainer.requestFullscreen) {
        videoContainer.requestFullscreen()
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      }
    }
    setIsFullScreen(!isFullScreen)
  }

  const formatTime = (time) => {
    const hours = Math.floor(time / 3600)
    const minutes = Math.floor((time % 3600) / 60)
    const seconds = Math.floor(time % 60)
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds

    if (hours > 0) {
      return `${hours}:${formattedMinutes}:${formattedSeconds}`
    } else if (minutes > 0) {
      return `${minutes}:${formattedSeconds}`
    } else {
      return `0:${formattedSeconds}`
    }
  }

  const toggleControlsVisibility = () => {
    setIsControlsVisible(!isControlsVisible)
  }

  return (
    <div className={`video-container ${isControlsVisible ? '' : 'controls-hidden'}`} onMouseEnter={() => setIsControlsVisible(true)} onMouseLeave={() => setIsControlsVisible(false)}>
      <video
        ref={videoRef}
        src={url}
        className="video-player"
        onClick={togglePlay}
        onError={onError}
      />
      <div className="controls">
        <div className="control-row">
          <button onClick={togglePlay} className="control-button">
            {isPlaying ? (
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <rect x="6" y="4" width="4" height="16"></rect>
                <rect x="14" y="4" width="4" height="16"></rect>
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <polygon points="5 3 19 12 5 21 5 3"></polygon>
              </svg>
            )}
          </button>
          {/* <button onClick={stop} className="control-button">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
            </svg>
          </button> */}
          <input
            type="range"
            min="0"
            max={duration}
            value={currentTime}
            onChange={(e) => seek(parseFloat(e.target.value))}
            className="seek-bar"
          />
          <div className="volume-control">
            <button onClick={toggleMute} className="control-button">
              {volume === 0 ? (
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <polygon points="11 5 6 9 2 9 2 15 6 15 11 19 11 5"></polygon>
                  <line x1="23" y1="9" x2="17" y2="15"></line>
                  <line x1="17" y1="9" x2="23" y2="15"></line>
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <polygon points="11 5 6 9 2 9 2 15 6 15 11 19 11 5"></polygon>
                  <path d="M19.07 4.93a10 10 0 0 1 0 14.14M15.54 8.46a5 5 0 0 1 0 7.07"></path>
                </svg>
              )}
            </button>
            <input
              type="range"
              min="0"
              max="1"
              step="0.1"
              value={volume}
              onChange={(e) => changeVolume(parseFloat(e.target.value))}
              className="volume-slider"
            />
          </div>
          <select
            value={playbackRate}
            onChange={(e) => changePlaybackRate(parseFloat(e.target.value))}
            className="playback-rate"
          >
            <option value="0.5">0.5x</option>
            <option value="1">1x</option>
            <option value="1.2">1.2x</option>
            <option value="1.5">1.5x</option>
            <option value="1.75">1.75x</option>
            <option value="2">2x</option>
          </select>
          <button onClick={toggleFullScreen} className="control-button">
            {isFullScreen ? (
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="M8 3v3a2 2 0 0 1-2 2H3m18 0h-3a2 2 0 0 1-2-2V3m0 18v-3a2 2 0 0 1 2-2h3M3 16h3a2 2 0 0 1 2 2v3"></path>
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="M8 3H5a2 2 0 0 0-2 2v3m18 0V5a2 2 0 0 0-2-2h-3m0 18h3a2 2 0 0 0 2-2v-3M3 16v3a2 2 0 0 0 2 2h3"></path>
              </svg>
            )}
          </button>
        </div>
        <div className="control-row">
          <span className="time-display">{formatTime(currentTime)} / {formatTime(duration)}</span>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  const { updateRecordingVideoCompletion } = actions;
  return {
    updateRecordingVideoCompletion: (lecture_id, completion_per) => dispatch(updateRecordingVideoCompletion(lecture_id, completion_per)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomVideoPlayer);