import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import actions from "../../redux/actions";
import reportsLoader from "../../public/icons/loader.png";
import "./UserLog.css";
import Header from "../../components/navigation/Header";
import "./AssessmentRecord.css";
import Back from '../../public/icons/back.svg';
import { Link } from 'react-router-dom';

const UserAssessment = (props) => {
    const { getUserAssessment} = props;
    const [assessmentList, setAssessmentList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [paginationDetail, setPaginationDetail] = useState({
        current_page: 1,
        total_pages: 0,
        count: 0,
        page_size: 10,
    });
    const { search } = window.location;
    const [searchQuery, setSearchQuery] = useState("");
    const params = new URLSearchParams(search);
    const assessment_id = params.get('assessment_id');
    const currentPage = params.get('currentPage');
    const userName = searchQuery ? searchQuery:''; 

    const userAssessmentList = async () => {
        setLoading(true);
        try {
            const response = await getUserAssessment(assessment_id, userName, currentPage);
            console.log("Response: ", response);

            if (response && response?.data) {
                setAssessmentList(response?.data?.results || []);
                setPaginationDetail(response?.data?.pagination || {});
                setError(null);
            } else {
                setAssessmentList([]);
                setError("No assessments found.");
            }
        } catch (e) {
            console.error("Error fetching report:", e);
            setError("Failed to fetch data. Please try again later.");
        } finally {
            setLoading(false);
        }
    };

    const handlePageChange = (newPage) => {
        if (newPage < 1 || newPage > paginationDetail.total_pages) return;
        userAssessmentList(newPage);
        setPaginationDetail((prev) => ({ ...prev, current_page: newPage }));
    };

    const handleSearchChange = (e) => setSearchQuery(e.target.value);

    useEffect(() => {
        userAssessmentList(paginationDetail.current_page);
    }, [paginationDetail.current_page, searchQuery]);

    return (
        <div className="admin-div">
            <Header placeholder="Assessment Reports" />

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <Link id="trace-back" to={`/admin/assessment-record`}>
                        <img src={Back} alt="Back" />
                    </Link>
                    <h1 style={{ margin: 0 }}>User Assessment Report</h1>
                </div>

                <input
                    type="text"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    placeholder="Search Username"
                    className="search-input"
                    style={{ maxWidth: '300px' }}
                />
            </div>

            {loading && (
                <div className="loading">
                    <img src={reportsLoader} alt="Loading..." />
                </div>
            )}

            {error && <div className="error-message">{error}</div>}

            {!loading && !error && assessmentList?.length > 0 && (
                <div className="table-container" style={{ overflowY: "scroll", maxHeight: "600px" }}>
                    <table className="assessment-table" style={{ width: '100%' }}>
                        <thead>
                            <tr style={{ position: "sticky", top: 0, background: "#fff", zIndex: 1 }}>
                                <th>UserId</th>
                                <th>Name</th>
                                <th>UserName</th>
                                <th>Passed Status</th>
                                <th>AttemptId</th>
                                <th>AssessmentId</th>
                                <th>IsLatest</th>
                                <th>Score</th>
                                <th>Submitted Status</th>
                                <th>SubmittedAt</th>
                            </tr>
                        </thead>
                        <tbody>
                            {assessmentList?.map((item) => (
                                <tr key={item?.user_id ?? ''}>
                                    <td>{item?.user_id ?? ''}</td>
                                    <td>{item?.name ?? ''}</td>
                                    <td>{item?.username ?? ''}</td>
                                    <td>{item?.is_passed ?? ''}</td>
                                    <td>{item?.attempt_id ?? ''}</td>
                                    <td>{item?.assessment_id ?? ''}</td>
                                    <td>{item?.is_latest ?? ''}</td>
                                    <td>{item?.score ?? ''}</td>
                                    <td>{item?.is_submitted ?? ''}</td>
                                    <td>{new Date(item?.submitted_at ?? '').toLocaleDateString()}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}

            {/* {!loading && !error && assessmentList.length === 0 && (
                <div className="no-data-message">Assessment Report Not Found!</div>
            )} */}

            <div
                style={{
                    position: 'sticky',
                    bottom: 0,
                    background: '#fff',
                    padding: '10px',
                    display: 'flex',
                    gap:'50px',
                    justifyContent: 'center',
                    zIndex: 10,
                    borderTop: '1px solid #ccc',
                }}
            >
                {!loading && !error && assessmentList.length > 0 && (
                    <div className="pagination" style={{}}>
                        <button
                            onClick={() => handlePageChange(paginationDetail.current_page - 1)}
                            disabled={paginationDetail.current_page <= 1}
                        >
                            Previous
                        </button>
                        <button
                            onClick={() => handlePageChange(paginationDetail.current_page + 1)}
                            disabled={paginationDetail.current_page >= paginationDetail.total_pages}
                        >
                            Next
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
    const { getUserAssessment } = actions;
    return {
        getUserAssessment: (assessment_id, username, page) =>
            dispatch(getUserAssessment(assessment_id, username, page)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserAssessment);
