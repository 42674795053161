import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router';
import ReactNotifications from 'react-notifications-component';
import actions from '../../redux/actions';
import Logo from '../../public/icons/favicon.svg';
import See from '../../public/icons/see.svg';
import Hide from '../../public/icons/hide.svg';
import ForgotImage from '../../public/icons/Group 270.svg';
import './styles.css';

import config from '../../config';

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = { 
    	userid: '',
    	otp_status:0,
      user_status:0,
    	otp: '',
    	password: '',
      new_password:'',
    	password_change: false,
      confirm_password:false,
    	roll: '',
    	dob: '',
    	message: '',
    	wrong_otp: false,
      hide: true
    }
  }

  componentDidMount() {
    //document.addEventListener('mousedown', this.handleClickOutside);
    const {name}=this.props.match.params;

    name=='learner' ? this.getLearnerProfile():this.getFacultyProfile();
  }

  componentWillUnmount() {
    //document.removeEventListener('mousedown', this.handleClickOutside);
    this.mounted = false;
  }

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ dropdisplay: false })
    }
  }

  getLearnerProfile = async () => {
    const response = await this.props.getLearnerProfile();
    this.setState({ profile: response.data, loading: false,
        no_data: response.data.message == "You are not assigned to any class and section, Please contact Admin for assigning into class"
          ? true : false })

    this.setState({userid:response.data.email})
  }

  getFacultyProfile = async () => {
    const response = await this.props.getFacultyProfile();
    this.setState({ profile: response.data, subjects: response.data.subjects })
    this.setState({userid:response.data.email})
  }

  handleChangeUserID = (e) => {
  	this.setState({ userid: e.target.value })
  } 

  handleChangePassword = (e) => {
  	this.setState({ password: e.target.value })
  }

  handleChangeNewPassword = (e) => {
    //e.target.value ===this.state.password?this.setState({confirm_password:true}):this.setState({confirm_password:false});
    this.setState({ new_password: e.target.value })
  }

  handleKeyPress = (e) => {
  	if (e.key === 'Enter') {
      this.sendOTP(this.state.userid,localStorage.getItem('lang'));
    }
  }

  changePassword = async (username,password,new_password) => {
    const response = await this.props.changePassword(username,password,new_password,localStorage.getItem('lang'));

    if(response){
      this.setState({loading: false,user_status:response.status,message:response.data})
    }
  }

  handleKeyPress2 = (e) => {
  	if (e.key === 'Enter') {
      this.changePassword(this.state.userid,this.state.password,this.state.new_password);
    }
  }

  handleKeyPress3 = (e) => {
    if (e.key === 'Enter') {
      this.sendQuery();
    }
  }

  hideChange = () => this.setState({ hide: !this.state.hide })

  render() {
      if(this.state.user_status==1){
        return <Redirect to="/login"/>
      }
      
      return (
    	  <div className="forgot-pw">
          <ReactNotifications />
            <Helmet>
              <title>Change Password</title>
            </Helmet>
            <div className="forgot-left">
              <div className="forgot-image">
                <img src={ForgotImage}/>
              </div>
            </div>
            <div className="forgot-right">
              <div className="forgot_header">
                {/* <span> <img src={require("../../public/images/"+config.brandLogo)} alt="Brand logo"/></span> */}
                {localStorage.getItem('logo_url') != 'undefined'? 
                    <span><img src={localStorage.getItem('logo_url')} alt="Brand logo"/></span>
                  : 
                  <span><img src='https://cdn.verandahighered.com/center-logos/sastra.png' alt="Brand logo"/></span>
                  }
              </div>
              <div className="forgot_title">
                Change Password
              </div>
              <div className="forgot-sub-title">
                
              </div>
              {<div className="fw_input fw_input2">
                <p style={this.state.wrong_otp ? {display: 'block'} 
                  : {display: 'none'}}>Wrong OTP. Enter the latest OTP.</p>
                
                <div className="input_field">
                   <input type={this.state.hide ? "password" : "text"}  placeholder='Password' name='password'
                    value={this.state.password} onKeyPress={this.handleKeyPress2}
                    onChange={this.handleChangePassword}/>
                  <div className="togglePassword" onClick={this.hideChange}>
                    {this.state.hide === true ? <img src={See} alt="see"/> 
                      : <img src={Hide} alt="hide"/>}
                  </div>
                </div>
                <div className="input_field">
                   <input type="password" placeholder='New Password' name='new_password'
                    value={this.state.new_password} onKeyPress={this.handleKeyPress2}
                    onChange={this.handleChangeNewPassword}/>
                    <div className="togglePassword" onClick={this.hideChange}>
                    {this.state.hide === true ? <img src={See} alt="see"/> 
                      : <img src={Hide} alt="hide"/>}
                  </div>
                </div>
                
                <div className="button-class">
                  <button onClick={() => this.changePassword(this.state.userid,this.state.password,this.state.new_password)} disabled="">
                    Change Password
                  </button>
                </div>
              </div>}
            </div>
        </div>
    	)
    }
  }

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading
  }
}

const mapDispatchToProps = (dispatch) => {
  const { sendOtp,changePassword,userLogin,getLearnerProfile,getFacultyProfile} = actions;
  return {
    sendOtp: (username,language) => dispatch(sendOtp(username,language)),
    getLearnerProfile: () => dispatch(getLearnerProfile()),
    getFacultyProfile: () => dispatch(getFacultyProfile()),
    changePassword: (username,otp,password,language) => dispatch(changePassword(username,otp,password,language)),
    userLogin: (username, password) => dispatch(userLogin(username, password)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);