import React from "react";
import './OrganizationOverview.css';

const OrganizationOverview = () => {
  return (
    <div className="admin-div">
    <div className="organization-overview">
     
      <div className="breadcrumb">
        {/* <span>Home</span> / <span>Organization Overview</span> */}
      </div>

      
      <div className="student-details-container">
        <h3 className="student-details-title">Student Details</h3>
        <input
          type="text"
          className="student-email-input"
          value="sakshamqa39@mailinator.com"
          readOnly
        />
      </div>

      
      <div className="action-buttons">
        <button className="btn disabled">View E-mail</button>
        <button className="btn">View Assessment</button>
        <button className="btn">View Assignment</button>
        <button className="btn">Login History</button>
        <button className="btn">Assign Program</button>
        <button className="btn">Completion Data</button>
      </div>

     
      <div className="user-not-found">
        <h2>User Not Found!</h2>
      </div>
    </div>
    </div>

  );
};

export default OrganizationOverview;
