import axios from 'axios';
import common from '../common';
import config from '../config.js';

class Service {
  constructor() {
    this.token = localStorage.getItem('token')
    let service = axios.create({
      baseURL:`${common.apiBase}`,
      headers: { 
        Authorization: 'token ' + this.token
      } 
    });
    service.interceptors.response.use(this.handleSuccess, this.handleError);
    this.service = service;

    let wpService = axios.create({
      baseURL:`${common.wpDomain}`
    });
    wpService.interceptors.response.use(this.handleSuccess, this.handleError);
    this.wpService = wpService;

    let Service1 = axios.create({
      baseURL:`${common.apiBase}`,
    });
    Service1.interceptors.response.use(this.handleSuccess, this.handleError);
    this.Service1 = Service1;

    let lmsService = axios.create({
      baseURL:`${common.lmsBaseUrl}`,
      headers: { 
        Authorization: 'token ' + this.token,
        'nlms-api-key' :'0613b32b39f4b29f48c5b5363028ee916bb91122',
      } 
    });
    lmsService.interceptors.response.use(this.handleSuccess, this.handleError);
    this.lmsService = lmsService;
  }

  handleSuccess(response) {
    if(!response.data.status) {

      return Promise.reject({'message':response.data.errors?response.data.errors:response.data.error})
    } 
    else {
      return response;
    }
  }

  handleError = (error) => {
    console.log("Amit")
    console.log(error.message)
    return Promise.reject(error)
  }

  redirectTo = (document, path) => {
    document.location = path
  }
  
  updateToken() {
    this.token = localStorage.getItem('token')
    let service = axios.create({
      baseURL:`${common.apiBase}`,
      headers: { 
        Authorization: this.token
      }
    });
    this.service = service;
  }

  updateSecretKey() {
    this.secret = localStorage.getItem('secret')
    let service = axios.create({
      baseURL:`${common.apiBase}`
    });
    service.interceptors.response.use(this.handleSuccess, this.handleError);
    this.service = service;
  }

  get(path, callback) {
    return this.service.get(path).then(
      (response) => callback(response.status, response.data)
    );
  }

  getw(path, callback) {
    return this.Service1.get(path).then(
      (response) => callback(response.status, response.data)
    );
  }

  wpGet(path, callback) {
    const username = config.wpUsername;
    const password = config.wpPassword;
    return this.wpService.get(path,{
      auth: {
        username:username,
        password:password
      }
    }).then(
      (response) => callback(response.status, response.data)
    );
  }

  patch(path, payload, callback) {
    return this.service.request({
      method: 'PATCH',
      url: path,
      responseType: 'json',
      data: payload
    }).then((response) => callback(response.status, response.data));
  }

  post(path, payload, callback) {
    return this.service.request({
      method: 'POST',
      url: path,
      responseType: 'json',
      data: payload
    }).then((response) => callback(response.status, response.data));
  }

  post33(path, payload) {
    return this.service.request({
      method: 'POST',
      url: path,
      responseType: 'json',
      data: payload,
    })
    .then(response => response.data) 
    .catch(error => {
      throw error;
    });
  }
  

  post1(path, payload, callback) {
    return this.Service1.request({
      method: 'POST',
      url: path,
      responseType: 'json',
      data: payload
    }).then((response) => callback(response.status, response.data));
  }

  lmsPost(path, payload, callback){
    return this.lmsService.request({
      method: 'POST',
      url: path,
      responseType: 'json',
      data: payload
    }).then((response) => callback(response.status, response.data));
  }
}


export default new Service();