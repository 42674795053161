import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { isEmpty } from 'lodash';
import Carousel from "react-multi-carousel";
import Popup from 'reactjs-popup';
import ReactNotifications from 'react-notifications-component';
import AssessmentPreview from '../../components/study-material/CommonComponents';
import BackArrow from '../../public/icons/mycoach-backarrow.svg';
import UnitsArrow from '../../public/icons/units-right-arrow.png';
import common from '../../common';
import mixpanel from 'mixpanel-browser';
import actions from '../../redux/actions';
import PathD from '../../public/icons/path-d.svg';
import PathB from '../../public/icons/path-b.svg';
import SMLib from '../../public/images/sm-lib.svg';
import ContentLib from '../../public/images/content-lib.png';
import Target from '../../public/icons/sm-assess.svg';
import Empty from '../../components/error/Empty';
import idea from '../../public/icons/idea.svg';
import Notes from '../../public/icons/notes.svg';
import Video from '../../public/icons/video.svg';
import 'react-notifications-component/dist/theme.css';
import Assignment from '../../public/icons/assignment.svg';
import "react-multi-carousel/lib/styles.css";
import './styles.css';
import config from '../../config';

const responsive = {
  desktop: {
    breakpoint: { max: 4240, min: 900 },
    items: 3,
    slidesToSlide: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 590 },
    items: 2,
    slidesToSlide: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1
  }
};

class StartTimer extends Component {
  constructor(props) {
    super(props)
    this.startTime = (new Date(this.props.startTime)).getTime();
    //this.second = 1000;
    //this.minute = this.second * 60;
    //this.hour = this.minute * 60;
    //this.postQuestions = []
    this.state = {
      //currentTime: props.startTime,
      differenceTime: this.props.count
    }
  }

  componentDidMount() {
    this.timer = setInterval(() => this.getCurrentTime(), 1000)
  }

  getCurrentTime = () => {

    let end = new Date();
    this.distance = this.startTime - end.getTime();
    //this.distance =  this.startTime - 10 * 60 * 1000;

    if (this.distance > 0)
      this.props.onChange(this.distance, this.state.differenceTime)
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  startTimerForNextQuestion = () => {

    this.startTime = (new Date()).getTime() - (this.props.time_taken * 1000);
    this.timer = setInterval(() => this.getCurrentTime(), 1000)
  }

  render() {
    return (
      <span className="timer" style={this.props.notify > 0 ? { color: '#ec6972' } : {}}>

      </span>
    )
  }
}


class StudyMaterial extends Component {
  constructor(props) {
    super(props);
    this.popupRef = React.createRef();
    this.state = {
      loading: true,
      is_faculty: localStorage.getItem('is_faculty') === 'true' ? true : false,
      subject_id: this.props.match.params.id,
      subject_label: this.props.location?.state?.subject_label,
      units: {},
      class_label: '',
      showModal: false,
      toc_ids: [],
      subjects: [],
      thumbnail: null,
      batch_id: 0,
      class_id: 0,
      is_archive: 0,
      getCurrentClassId: '',
      enable_toc_accordian: JSON.parse(localStorage.getItem('logindetails')).institute_settings.enable_toc_accordian ?? '',
      accordianActivatedId: null,
      sessionDialogOpen: false,
      allSubjectSessionList: [],
      currentLecture: null,
      is_view_recording: 0,
      username: localStorage.getItem("username"),
      userID: localStorage.getItem("user_id"),
      token: localStorage.getItem("token"),
      allSubjectSessionList: [],
      filteredLectures: [],
      sessionDialogOpen: false
    }
  }

  componentDidMount() {

    let backLocation = this.props.location;
    console.log("backLocation", backLocation.pathname);

    localStorage.setItem('prevLocation', backLocation.pathname);
    localStorage.setItem('fromtopic', false);

    this.state.is_faculty ? this.getFacultyProfile() : this.getLearnerProfile();

    console.log("this.props.location?.state.is_archive");
    console.log(this.props.location?.state?.is_archive);
    let getis_archive = this.props.location?.state?.is_archive;
    this.setState({ is_archive: getis_archive });
    this.getStudyMaterial(getis_archive);






    let getCurrentClassId = this.props.location?.state?.currentClassid;
    console.log("getCurrentClassId", getCurrentClassId);
    this.setState({ getCurrentClassId: getCurrentClassId });
    if (getCurrentClassId) {
      localStorage.setItem('set_is_archive', true)
    } else {
      localStorage.setItem('set_is_archive', false)
    }


    this.getStudentLectureList();



  }




  getStudentLectureList = async () => {
    let save_subjectid = this.props.match.params;
    console.log("save_subjectid", save_subjectid.id);
    const response = await this.props.getStudentSessionsList('1970-05-01', '999999999', "999999999", 'forward', save_subjectid?.id, 'true');

    if (response) {
      console.log("response", response);
      this.setState({ allSubjectSessionList: response?.data?.lectures });



    }
  }



  getStudyMaterial = async (getis_archive) => {


    if (getis_archive) {
      var response = await this.props.getStudentTOC(this.state.subject_id, getis_archive ? "1" : '');
    } else {
      var response = await this.props.getStudentTOC(this.state.subject_id);
    }


    this.setState({
      units: response.data.toc_data.units,
      toc_ids: common.getContentIds(response?.data?.toc_data?.units), loading: false
    });



  }

  getLearnerProfile = async () => {

    const response = await this.props.getLearnerProfile();
    const subjectIndex = response.data.all_subjects.findIndex(p => parseInt(p.id) === parseInt(this.state.subject_id))
    //if(!this.state.subject_label) this.setState({subject_label:response.data.subjects[subjectIndex]?.label});
    if (!this.state.subject_label) this.setState({ subject_label: response.data.all_subjects[subjectIndex]?.label });
    this.setState({ subjects: response.data.subjects });
    this.getBatchName(response.data.batches);
    this.getSubject(response.data);
  }

  getFacultyProfile = async () => {

    const response = await this.props.getFacultyProfile();
    const subjectIndex = response.data.subjects.findIndex(p => parseInt(p.id) === parseInt(this.state.subject_id))
    if (!this.state.subject_label) this.setState({ subject_label: response.data.subjects[subjectIndex].label });
    this.setState({ subjects: response.data.subjects });
    this.getSubject(response.data);
    this.getBatchName(response.data.batches);
  }

  getSubject = (val) => {
    const subjectId = this.props.match.params;
    if (this.state.is_faculty) {
      if (val.subjects && val.subjects.length) {
        for (let i = 0; i < val.subjects.length; i++) {
          console.log(val.subjects[i].id)
          if (parseInt(subjectId.id) === parseInt(val.subjects[i].id)) {

            this.setState({ thumbnail: val.subjects[i].thumbnail });
            this.setState({ class_label: val.subjects[i].class_name });
            this.setState({ class_id: val.subjects[i].class_id });
          }
        }
      }
    }
    if (!this.state.is_faculty) {
      if (val.all_subjects && val.all_subjects.length) {
        for (let i = 0; i < val.all_subjects.length; i++) {
          console.log(val.all_subjects[i].id)
          if (parseInt(subjectId.id) === parseInt(val.all_subjects[i].id)) {

            this.setState({ thumbnail: val.all_subjects[i].thumbnail });
            this.setState({ class_label: val.all_subjects[i].class_name });
            this.setState({ class_id: val.all_subjects[i].class_id });
          }
        }
      }
    }
  }

  getBatchName = (val) => {
    if (val && val.length) {
      for (let i = 0; i < val.length; i++) {
        // if(parseInt(val[i].program.id) === parseInt(this.state.class_id))
        if (parseInt(val[i].classs.id) === parseInt(this.state.class_id))
          this.setState({ batch_id: val[i].id });
        console.log("batch id mili");
        console.log(this.state.batch_id);
      }
    }
  }

  attemptAndReview = async (label, content_id) => {
    this.setState({ content_id })
    if (label.toLowerCase() === 'review' || label.toLowerCase() === 'result') {
      this.props.history.push(
        `/learner/assessment-review/${content_id}`,
        { modal: true }
      )
      mixpanel.track("Assessment Attempt Btn", {
        userName: this.state.username,
        userId: this.state.userID,
        label: label,
        contentId: content_id,
      })

    }
    else {
      await this.props.getAttemptPreview(content_id)
      this.setState({ showModal: !this.state.showModal })
    }
  }

  showAttemptModal = () => this.setState({ showModal: true })

  closeAttemptModal = () => this.setState({ showModal: false })

  attemptPaper = (label) => {
    const contentPreview = this.props.contentPreview;
    if (label.toLowerCase() == 'review' || label.toLowerCase() == 'result') {
      this.props.history.push(
        `/learner/assessment-review/${contentPreview.content_id}`,
        { modal: true }
      )
    }
    else {
      this.setState({ showAttemptModal: false })
      this.props.history.push(
        `/learner/attempt/${contentPreview.content_id}`
      );
    }
  }

  getColor = (content) => {
    if (content != undefined) {
      if (content.content_subtype === 4 || content.content_subtype === 5) {
        return {}
      }
      else {
        if (content.actions.length === 1) {
          if (content.actions[0].label.toLowerCase() === 'attempt') {
            return { backgroundColor: 'config.themePrimaryLightColor' };
          }
          else {
            return { backgroundColor: config.themePrimaryLightColor };
          }
        }
        else {
          return { backgroundColor: config.themePrimaryLightColor };
        }
      }
    }
  }

  contentScore = (val) => {

    if (isEmpty(val)) {
      return false;
    } else {
      return val;
    }
  }


  AccordianClick = (id) => {
    console.log(id);
    if (id) {
      this.setState({ accordianActivatedId: (id == this.state.accordianActivatedId || this.state.accordianActivatedId == null) ? '' : id })
      // document.querySelector('.accordianActivate').classList.remove('accordianActivated')
      // document.getElementById(id).classList.add('accordianActivated');
    }
  }

  // sessionDialogOpen = () => {
  //   this.setState({sessionDialogOpen : true});
  //   document.body.classList.add('studyMaterialPage');
  //   const currentDate = new Date();

  //   // Find the lecture element for the current date
  //   const currentLecture = this.state.allSubjectSessionList.find(lecture => {
  //     const lectureDate = new Date(lecture.start_date_time);
  //     console.log("lectureDate",lectureDate)
  //     return lectureDate.toDateString() === currentDate.toDateString();
  //   });



  //   setTimeout(() => {
  //     if (currentLecture?.id) {
  //       ///currentLecture.scrollIntoView({ behavior: 'smooth', block: 'center' });
  //       let selectedElement = document.getElementById(currentLecture.id).scrollIntoView()
  //         // selectedElement.scrollIntoView();
  //         console.log(selectedElement)	// we console log it for testing
  //     }
  //   },1000)
  //   // Scroll the lecture into view


  // }


  sessionDialogOpen = () => {
    this.setState({ sessionDialogOpen: true }, () => {
      document.body.classList.add('studyMaterialPage');

      const subjectName = this.props.location?.state?.subject_label;
      if (!subjectName) {
        console.log("No subject label provided.");
        return;
      }

      const currentDate = new Date().toDateString();

      // Filter lectures by subject
      const filteredLectures = this.state.allSubjectSessionList.filter(lecture => {
        const lectureDate = new Date(lecture.start_date_time).toDateString();
        return lecture.subject.toUpperCase() === subjectName.toUpperCase();
      });

      // Sort the lectures in descending order by date, with current date lecture on top
      const sortedLectures = filteredLectures.sort((a, b) => {
        const aDate = new Date(a.start_date_time).toDateString();
        const bDate = new Date(b.start_date_time).toDateString();

        if (aDate === currentDate && bDate !== currentDate) return -1;
        if (aDate !== currentDate && bDate === currentDate) return 1;
        return new Date(b.start_date_time) - new Date(a.start_date_time); // Descending order
      });

      this.setState({ filteredLectures: sortedLectures }, () => {
        console.log("Sorted lectures:", sortedLectures);

        if (sortedLectures.length > 0) {
          setTimeout(() => {
            const selectedElement = document.getElementById(sortedLectures[0].id);
            if (selectedElement) {
              selectedElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
              console.log("Scrolling to element:", selectedElement);
            } else {
              console.log(`Element with id ${sortedLectures[0].id} not found.`);
            }
          }, 100);
        } else {
          console.log("No lecture found for the given subject.");
        }
      });
    });
  };






  sessonDisplayDialogClose = () => {
    this.setState({ sessionDialogOpen: false })
    document.body.classList.remove('studyMaterialPage');
  }

  // getTimeAMPMFormat = (date) => {
  //   let hours = date.getHours();
  //   let minutes = date.getMinutes();
  //   const ampm = hours >= 12 ? 'PM' : 'AM';
  //   hours = hours % 12;
  //   hours = hours ? hours : 12; // the hour '0' should be '12'
  //   hours = hours < 10 ? '0' + hours : hours; // appending zero in the start if hours less than 10
  //   minutes = minutes < 10 ? '0' + minutes : minutes;
  //   return hours + ':' + minutes + ' ' + ampm;
  // };

  getTimeAMPMFormat = (dateString, durationHours) => {
    const date = new Date(dateString);
    const day = ('0' + date.getDate()).slice(-2); // pad with leading zero
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // pad with leading zero
    const year = date.getFullYear();
    let hours = date.getHours();
    let minutes = ('0' + date.getMinutes()).slice(-2); // pad with leading zero
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    hours = ('0' + hours).slice(-2); // pad with leading zero
    const formattedDate = `${day}-${month}-${year} ${hours}:${minutes} ${ampm}`;
    return formattedDate;
  };



  recordingVideoOpen = (url, lecture_id, recordingLinkClick) => {
    let recordingLinkClickValue = recordingLinkClick;
    let recordingUrl = url;
    this.setState({ id: lecture_id })
    localStorage.setItem('recordingUrl', recordingUrl)
    localStorage.setItem('viewrecordingButtoncLick', true)
    this.zoomAttendance(url, lecture_id);
    mixpanel.track("View Session", {
      lectureId: lecture_id,

    })
  }



  zoomAttendance = (lectureURL, lectureid) => {
    console.log("lectureid")
    console.log(lectureid)
    let { id, userID, currentLectureURL } = this.state;
    this.setState({ currentLectureURL: lectureURL });
    this.setState({ id: lectureid });
    var today = new Date();
    var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    var dateTime = date + ' ' + time;
    console.log("id", dateTime)
    console.log("userID", userID)
    console.log("dateTime", dateTime)
    console.log("currentLectureURL", currentLectureURL)
    if (id, userID, dateTime) {
      this.zoomAttendanceAPI(lectureid, userID, dateTime);
    }

  }

  // zoomAttendanceAPI = async (lecture_id,user_id,join_time) =>{
  //   const response = await this.props.callzoomAttendanceAPI(lecture_id,user_id,join_time);

  //     this.setState({is_attendanceURL:false});
  //     console.log("attendanceURL", this.state.is_attendanceURL);
  //     console.log("currentLectureURL", this.state.currentLectureURL);
  //     window.open(this.state.currentLectureURL,"_blank");
  //   }

  zoomAttendanceAPI = async (lecture_id, user_id, join_time) => {
    let { recordingActionLabel } = this.state;

    const response = await this.props.callzoomAttendanceAPI(lecture_id, user_id, join_time, localStorage.getItem('viewrecordingButtoncLick') ? "recording" : 'attendance');

    this.setState({ is_attendanceURL: false });
    console.log("attendanceURL", this.state.is_attendanceURL);
    console.log("currentLectureURL", this.state.currentLectureURL);
    console.log("responsesssssss", response)
    console.log("response.data.url", response?.data?.url)
    localStorage.removeItem('viewrecordingButtoncLick');
    if (response !== undefined || response?.status == 1) {
      //window.open(response.data.url,"_blank");
    }
    //window.open(this.state.currentLectureURL,"_blank");
  }

  onSchedule = (val, id) => {
    this.timer = setInterval(() => this.scheduledOn(new Date(val), id), 1000)
  }


  scheduledOn = (val, due, id) => {
    let dt1 = new Date();
    let currentTime = '';
    var diff = val.getTime() / (1000 * 60) - dt1.getTime() / (1000 * 60);
    diff = Math.round(diff);
    if (diff <= 0) {
      currentTime = val.getTime();
      this.timer = setInterval(() => this.scheduledOn1(new Date(), currentTime, id), 1000)
      //setTimeout(function(){  window.location.reload(true) },60000);
    }
    //console.log("onsched"+diff)
  }

  scheduledOn1 = (val1, currentTime, id) => {
    var diff = val1.getTime() / (1000 * 60) - currentTime / (1000 * 60);
    diff = Math.round(diff);
    if (diff >= 15) {
      clearInterval(this.timer);
      this.setState({ is_view_recording: 1 });
      clearInterval(this.timer);
      //setTimeout(function(){  window.location.reload(true) },60000);
    }
    //console.log("onsched7878"+diff)
  }


  render() {
    mixpanel.init('6f29577c37a87900c42872e181406747', {
      debug: true

    });

    const lec_modes = {
      2: this.props.saveLanguageWiseData.live,
      0: this.props.saveLanguageWiseData.classroom,
      1: 'Online'
    }

    const thumbNail = this.state.thumbnail;

    if (this.props.loading || this.state.loading) {
      return (
        <div className="app-body">
          <div className="app-div">
            <img id="path-b" src={PathB} alt="pathb" />
            <img id="path-d" src={PathD} alt="pathd" />
            <div className="study-material-loader"><div className='admin-loader '></div></div>
          </div>
        </div>
      )
    }
    return (
      <>
        {!isEmpty(this.props.contentPreview)
          && <Popup className="attempt-modal" open={this.state.showModal} closeOnDocumentClick
            onClose={this.closeAttemptModal}>
            <AssessmentPreview
              contentPreview={this.props.contentPreview}
              attempt={this.attemptPaper}
              showAttemptButton={true}
            />
          </Popup>}

        <div className={this.state.sessionDialogOpen ? "sessonDisplayDialog open" : "sessonDisplayDialog"}>
          <div className='sessonDisplayContainer'>
            <div className='dialogClose' onClick={() => this.sessonDisplayDialogClose()}><i className='fa fa-times'></i></div>
            <h3>Sessions {this.state.class_label} | {this.state.subject_label} | {this.props.saveLanguageWiseData.units}</h3>
            <div className='SessonContentList' ref={this.popupRef} >
              <ol>
                {/* {JSON.stringify(this.state.allSubjectSessionList)} */}
                {this.state.filteredLectures && this.state.filteredLectures.map((lecture, index) =>
                  <li key={lecture.id || index} id={lecture.id}>
                    {lecture.start_date_time && <StartTimer startTime={lecture.start_date_time} onChange={this.saveQuestionTime}
                      count={0} ref={this.questionTimer} />}

                    {this.onSchedule(lecture.end_date_time, lecture.id)}
                    <div className='sessonInfo'>
                      <div className='session-band'>
                        <span>{lec_modes[lecture.mode]}</span>
                      </div>
                      <h3>{lecture.title}</h3>
                      <p> <span>
                        {this.getTimeAMPMFormat(new Date(lecture.start_date_time))} | {lecture.duration_hrs} {this.props.saveLanguageWiseData.hrs}
                      </span></p>
                    </div>


                    <div className='actions'>
                      <div>
                        <div className="fac_dp_img">
                          {lecture.profile_pic !== '' && lecture.profile_pic !== null
                            ? <div className="fac_dp" style={{
                              backgroundImage:
                                `url(${common.addFileUrl(lecture?.profile_pic)})`
                            }}>
                            </div>
                            : null}
                          <span>{lecture.faculty}</span>
                        </div>
                        {lecture.mode === 0 ? <span>{this.props.saveLanguageWiseData.location}: {lecture.url}</span> : null}
                      </div>
                      {lecture.action_label !== null && lecture.action_label !== '' && lecture.action_label
                        !== undefined ? lecture.action_label === "Join Class"
                        ? <span Style="margin-left: 21px; justify-content:flex-end; display:flex;"> {lecture.liveClass_server == 2 ? <a href="#" onClick={() => this.bbbJoinLiveClass(lecture.etc, lecture.live_class_Session)} target="_blank" >
                          <button className='Hello'>{lecture.action_label}</button>
                        </a> : lecture.liveClass_server == 3 ? <a onClick={() => this.zoomAttendance(lecture.open_url, lecture.id)} href={lecture.open_url} title="join classggggg" target="_blank" ><button>{lecture.action_label}</button>
                        </a> : <a href={lecture.url} target="_blank">
                          <button className='Hell222o' onClick={() => this.zoomAttendance(lecture.open_url, lecture.id)}>{lecture.action_label}</button>
                        </a>}</span> : <span id="action-reverse">
                          {lecture.action_label != "View Recording" ? lecture.action_label : ""}
                          {/* {lecture.record_available && this.state.is_view_recording ?<span id="view-recording"><a href={lecture.record_url || lecture.url} rel="noreferrer noopener" target="_blank">
                            <button>{this.props.saveLanguageWiseData.view_recording}</button>
                          </a></span>:""} */}
                          {lecture.record_available && this.state.is_view_recording ? <span id="view-recording"><Link onClick={() => this.recordingVideoOpen(lecture.record_url || lecture.url, lecture.id, "recordingLinkClick")} target="_blank" to={'/learner/view-recording/' + lecture.id}>
                            <button>{lecture.action_label}</button>



                          </Link></span> : ""}


                        </span> : null}



                      {parseInt(this.props.learnerProfile?.institute_settings?.show_feedback_link) === 1 && <a href="https://docs.google.com/forms/d/e/1FAIpQLSfcnU5BbeSTX-fvsCxUIIyklU04quhtzD2K_yqT85sTbCsitw/viewform" target="_blank">
                        <button>{this.props.saveLanguageWiseData.feedback}</button>
                      </a>}
                    </div>
                  </li>
                )}

                {this.state.allSubjectSessionList && this.state.allSubjectSessionList.length == 0 && <div className='noContentFound'>No Session  Found</div>}
              </ol>
            </div>
          </div>
        </div>


        <div className="app-body">
          <div className={"app-div app-studymaterial studymaterialpage widget-app-div " + (this.state.is_faculty ? 'is_faculty' : '')}>
            <Helmet>
              <title>{`${this.props.saveLanguageWiseData.study_material} - ${this.props.saveLanguageWiseData.faculty}`}</title>
            </Helmet>
            <ReactNotifications />
            <img id="path-b" src={PathB} alt="pathb" />
            <img id="path-d" src={PathD} alt="pathd" />
            <div className="ViewAllSessionsLink" onClick={() => this.sessionDialogOpen()}><span>View all Sessions</span><i className='fa fa-chevron-right'></i></div>



            <div className="study-material-main">
              {this.state.getCurrentClassId !== undefined &&
                <div className="viewingOldSemesterData">You are viewing old semester data</div>
              }

              <div className="heading alignLeft">
                {this.state.getCurrentClassId !== undefined &&
                  <Link id="trace-back"
                    to={{
                      pathname: `/learner`,
                      state: { currentClassId: this.state.getCurrentClassId }
                    }}>
                    <img src={BackArrow} alt="back with class id" />
                  </Link>
                }
                {this.state.getCurrentClassId === undefined &&
                  //  <div id="trace-back" onClick={() => this.props.history.goBack()}>
                  //     <img src={BackArrow} alt="back"/>
                  //   </div>
                  <Link id="trace-back"
                    to={{
                      pathname: `/learner`,
                      state: { currentClassId: '' }
                    }}>
                    <img src={BackArrow} alt="back" />
                  </Link>
                }
                <h2>{this.state.class_label} | {this.state.subject_label} | {this.props.saveLanguageWiseData.units}</h2>
                <div>
                  {/* this.state.is_faculty ? <Link to={{pathname: `/faculty/assignment/${this.state.subject_id}`,
                  state: { subject:this.state.subject_id,subjectName:this.state.subject_label}}}>
                  <button>
                  View Assignment
                  </button>
                </Link>:<Link to={{pathname: `/learner/assignment/${this.state.subject_id}`,
                  state: { subject:this.state.subject_id,subjectName:this.state.subject_label}}}>
                  <button>
                  View Assignment
                  </button>
                </Link>
              */}
                </div>
              </div>
              <div className='subjectBanner'>
                <img src={common.addFileUrl(this.state.thumbnail)} />
              </div>

              {/* {JSON.stringify(this.state.enable_toc_accordian)}    */}


              {this.state.units.length === 0 && <Empty
                statement={`No content is created yet in ${this.state.subject_label}`} />}


              {this.state.units && this.state.units.map((unit, index) =>
                <div className={`sm-units newDesign ${this.state.enable_toc_accordian == 1 ? "accordianActivate" : ''} `} key={unit.id} id={unit.id}>
                  <div className={`sm-unit-div ${this.state.enable_toc_accordian == 1 ? "accordianActivate" : ''} `}>
                    <div className="sm-unit-div1" onClick={(e) => this.AccordianClick(unit.id)}>

                      <span>{unit.label}</span>
                      {/* <img src={SMLib} alt="img"/> */}
                      {this.state.enable_toc_accordian == 1 ?
                        (((this.state.accordianActivatedId == null && index == 0) || (this.state.accordianActivatedId == unit.id))) ? <i className=" accrodianArrow accrodianUpArrow  fa fa-chevron-down"></i> : <i className=" accrodianArrow fa fa-chevron-down"></i> : ''
                      }
                    </div>
                    {((this.state.enable_toc_accordian == 1 && ((this.state.accordianActivatedId == null && index == 0) || (this.state.accordianActivatedId == unit.id))) || this.state.enable_toc_accordian == 0) && unit?.content?.length ?
                      <div className="sm-unit-div2 dfdsfsdf">
                        <Carousel containerClass="sm-chapters" responsive={responsive}
                          keyBoardControl={false} arrows={false} swipeable={true} draggable={false}
                          removeArrowOnDeviceType={["tablet", "mobile"]}>
                          {unit.content.length > 0 ? unit?.content?.map(unit =>
                            <>
                              {unit.content_type == 1 && unit.content_subtype == 4 &&
                                <Link className="sm-unit-content newdessss" to={{
                                  pathname: !this.state.is_faculty
                                    ? `/learner/classroom/${this.state.subject_id}`
                                    : `/faculty/classroom/${this.state.subject_id}`,
                                  state: { table_id: unit.id, subject_label: this.state.subject_label, fromStudyMaterial: true, content_subtype_name: unit.content_subtype, content_id: unit.content_id, content_id: unit.content_id, assignment_id: unit.assignment_id, assignment_id: unit.assignment_id }
                                }} style={this.getColor(unit)}
                                  onClick={() => this.props.getContentPreview(unit.content_id, unit.content_subtype)}>
                                  {isEmpty(unit?.thumbnail) ? <div className='sm-unit-content-thumbnail'><img src={common.getContentIcon(unit.content_subtype)} style={{ height: '100px', width: '100px', marginTop: '20px' }} alt="img" /></div> : <div className="sm-unit-content-thumbnail"
                                  ><img src={common.addFileUrl(unit?.thumbnail)} alt="img" />
                                  </div>}

                                  <center>  <p>{unit.title}</p></center>

                                </Link>
                              }

                              {unit.content_type == 1 && unit.content_subtype == 5 &&
                                <Link className="sm-unit-content" to={{
                                  pathname: !this.state.is_faculty
                                    ? `/learner/classroom/${this.state.subject_id}`
                                    : `/faculty/classroom/${this.state.subject_id}`,
                                  state: { table_id: unit.id, subject_label: this.state.subject_label, fromStudyMaterial: true, assignment_id: unit.assignment_id }
                                }} style={this.getColor(unit)}
                                  onClick={() => this.props.getContentPreview(unit.content_id, unit.content_subtype)}>
                                  {isEmpty(unit?.thumbnail) ? <div className='sm-unit-content-thumbnail'><img src={common.getContentIcon(unit.content_subtype)} style={{ height: '100px', width: '100px', marginTop: '20px' }} alt="img" /></div> : <div className="sm-unit-content-thumbnail"
                                  ><img src={common.addFileUrl(unit?.thumbnail)} alt="img" />
                                  </div>}
                                  <center> <p>{unit.title}</p></center>
                                </Link>
                              }


                              {unit?.content_type == 2 && unit.content_subtype == 1 && <div className="sm-item pppppppppp"><Link style={this.getColor(unit)} className="sm-item " to={{
                                pathname: !this.state.is_faculty
                                  ? `/learner/classroom/${this.state.subject_id}`
                                  : `/faculty/classroom/${this.state.subject_id}`,
                                state: { table_id: unit.id, subject_label: this.state.subject_label, fromStudyMaterial: true, assignment_id: unit.assignment_id, is_archive: this.state.is_archive }
                              }}
                                // style={this.getColor(unit)}
                                onClick={() => this.props.getContentPreview(unit.content_id, unit.content_subtype)}>
                                <div className='sm-content-title'>
                                  {/* <img src={unit.assignment_id ? Assignment : Target} id="sm-assessm" alt="img" /> */}
                                  <img src={common.getContentIcon(unit.content_subtype)} style={{ height: '100px', width: '100px', marginTop: '20px' }} alt="img" />
                                <center>  <p>{unit.title}</p></center>

                                  {/* {!this.state.is_faculty && unit.is_graded && 
                              <span Style="margin-bottom: 10px;">
                                <b Style={!this.contentScore(unit.score) ? "display:none" : ""}>Score :</b>{this.contentScore(unit.score)}<br/>
                              </span>} */}

                                  {unit.actions && unit.actions.map(action =>
                                    (action.label == 'Review' || action.label == 'Result') &&
                                    !this.state.is_faculty && unit.is_graded &&
                                    <span Style="margin-bottom: 10px;">
                                      <b Style={!this.contentScore(unit.score) ? "display:none" : ""}>Score :</b>{this.contentScore(unit.score)}<br />
                                    </span>

                                  )}

                                </div>
                              </Link>
                                {unit.actions.length > 0 && !this.state.is_faculty ?
                                  <div className="sm-content-actions tytyty">
                                    {unit.actions && unit.actions.map(action =>
                                      <div key={action.id} onClick={() => this.attemptAndReview(action.label, unit.content_id)}>
                                        {this.state.is_archive !== undefined && (action.label == "Review" || action.label == "Result") &&
                                          <button>{action.label}</button>
                                        }
                                        {this.state.is_archive == undefined &&
                                          <button>{action.label}</button>
                                        }
                                      </div>
                                    )}
                                  </div> : <div className="sm-content-actions lll">
                                    {unit.actions && unit.actions.map(action =>
                                      <Link key={unit.content_id} to={{
                                        pathname: `/faculty/assessment-review/${unit.content_id}`,
                                        state: { action: action.label, batch: this.state.batch_id, is_subject: 1, modal: true, class_id: this.state.class_id, subject: this.state.subject_label, id: this.state.subject_id }
                                      }}>
                                        <button>
                                          {action.label}
                                        </button>
                                      </Link>
                                    )}
                                  </div>}
                              </div>
                              }

                              {unit?.content_type == 3 && unit.content_subtype == 0 && <div className="sm-item"><Link style={this.getColor(unit)} className="sm-item " to={{
                                pathname: !this.state.is_faculty
                                  ? `/learner/classroom/${this.state.subject_id}`
                                  : `/faculty/classroom/${this.state.subject_id}`,
                                state: { table_id: unit.id, subject_label: this.state.subject_label, fromStudyMaterial: true, assignment_id: unit.assignment_id }
                              }}
                                // style={this.getColor(unit)}
                                onClick={() => this.props.getContentPreview(unit.content_id, unit.content_subtype)}>
                                <div className="sm-content-title">
                                  {/* <img src={unit.assignment_id ? Assignment : Target} id="sm-assessm" alt="img" /> */}
                                  <img src={common.getContentIcon(unit.content_subtype)} style={{ height: '100px', width: '100px', marginTop: '20px' }} alt="img" />
                                  {!this.state.is_faculty && unit.is_graded && <span Style="margin-bottom: 10px;"><b Style={!this.contentScore(unit.score) ? "display:none" : ""}>{this.props.saveLanguageWiseData.score} :</b>{this.contentScore(unit.score)}<br /></span>}
                                  <span>{unit.title}</span>
                                </div>
                              </Link>


                                {this.state.is_archive !== '' && unit.actions.length > 0 && !this.state.is_faculty &&
                                  <div style={{ paddingLeft: '90px', paddingBottom: '20px' }}>
                                    {unit.actions && unit.actions.map(action =>
                                      <Link key={unit.assignment_id} to={{
                                        pathname: `/learner/assignment-review/${unit.assignment_id}`,
                                        state: { action: action.label, batch: this.state.batch_id, is_subject: 1, modal: true, class_id: this.state.class_id, subject: this.state.subject_label, id: this.state.subject_id, is_archive: true }
                                      }}>
                                        <button>
                                          {action.label}
                                        </button>
                                      </Link>
                                    )}
                                  </div>}
                                {this.state.is_archive === '' && unit.actions.length > 0 && this.state.is_faculty &&
                                  <div className="sm-content-action hello">
                                    {unit.actions && unit.actions.map(action =>
                                      <Link key={unit.assignment_id} to={{
                                        pathname: `/faculty/assignment-review/${unit.assignment_id}`,
                                        state: { action: action.label, subject: this.state.subject_label, id: this.state.subject_id, class_id: this.state.class_id }
                                      }}>
                                        <button>
                                          {action.label}
                                        </button>
                                      </Link>
                                    )}
                                  </div>}



                              </div>
                              }
                            </>

                          ) : <div className="sm-unit-content-noexist">
                            {isEmpty(thumbNail) ? <img src={ContentLib} alt="img" /> :
                              <div className="sm-unit-content-thumbnail"
                                style={{ backgroundImage: `url(${common.addFileUrl(this.state.thumbnail)})` }}>
                              </div>
                            }
                            <div>{unit.label}</div></div>
                          }
                        </Carousel>

                      </div>
                      : ""}
                  </div>
                  {((this.state.enable_toc_accordian == 1 && ((this.state.accordianActivatedId == null && index == 0) || (this.state.accordianActivatedId == unit.id))) || this.state.enable_toc_accordian == 0) && unit.chapters.map(chapter =>
                    <div className="sm-layers wid100" key={chapter.id}>
                      <h3>{chapter.label}</h3>
                      <Carousel containerClass="sm-chapters" responsive={responsive}
                        keyBoardControl={false} arrows={false} swipeable={true} draggable={false}
                        removeArrowOnDeviceType={["tablet", "mobile"]}>
                        {chapter.content.map(content => <>
                          {content.content_subtype === 4 || content.content_subtype === 5
                            ? <Link className="sm-item" to={{
                              pathname: !this.state.is_faculty
                                ? `/learner/classroom/${this.state.subject_id}`
                                : `/faculty/classroom/${this.state.subject_id}`,
                              state: { table_id: chapter.id, subject_label: this.state.subject_label }
                            }}
                              onClick={() => this.props.getContentPreview(content.assignment_id ? content.assignment_id : content.content_id, content.content_subtype)}
                              key={content.content_id} style={this.getColor(content)}>
                              {content.content_subtype === 4 || content.content_subtype === 5
                                ? <div className="sm-thumbnail">
                                  {content?.thumbnail?.length === 0 || content?.thumbnail === null
                                    ? <div className="sm-thumbnail-img">
                                      <img src={SMLib} alt="thumbnail" />
                                      {content.content_subtype === 4 ? <img src={Notes} id="sm-icon" alt="img" />
                                        : content.content_subtype === 5 ? <img src={Video} id="sm-icon" alt="img" />
                                          : null}
                                    </div> : <div className="sm-thumbnail-img" style={{
                                      backgroundImage:
                                        `url(${common.addFileUrl(content?.thumbnail)})`
                                    }}>
                                      {content.content_subtype === 4 ? <img src={Notes} id="sm-icon" alt="img" />
                                        : content.content_subtype === 5 ? <img src={Video} id="sm-icon" alt="img" />
                                          : null}
                                    </div>}
                                </div> : null}
                              <div className="sm-content-title">
                                <span>{content.title}</span>
                              </div>
                              {content.actions.length > 0 && !this.state.is_faculty && <div className="sm-content-actions ddddd">
                                {content.actions && content.actions.map(action =>
                                  <div key={action.id}
                                    onClick={() => this.attemptAndReview(action.label, content.content_id)}>
                                    <button>
                                      {action.label}
                                    </button>
                                  </div>
                                )}
                              </div>}

                              {content.actions.length > 0 && !this.state.is_faculty && content.content_subtype === 0 && <div className="sm-content-actions oppopp">
                                {content.actions && content.actions.map(action =>
                                  <Link key={content.assignment_id} to={{
                                    pathname: `/learner/assignment-review/${content.assignment_id}`,
                                    state: { action: action.label, batch: this.state.batch_id, is_subject: 1, modal: true, class_id: this.state.class_id, subject: this.state.subject_label, id: this.state.subject_id }
                                  }}>
                                    <button>
                                      {action.label}
                                    </button>
                                  </Link>
                                )}
                              </div>}

                              {this.state.is_faculty && <div className="sm-content-actions rrrrr">
                                {content.actions && content.actions.map(action =>
                                  <>{content.content_subtype === 1 ? <Link key={content.content_id} to={{
                                    pathname: `/faculty/assessment-review/${content.content_id}`,
                                    state: { action: action.label, batch: this.state.batch_id, is_subject: 1, modal: true, class_id: this.state.class_id, subject: this.state.subject_label, id: this.state.subject_id }
                                  }}>
                                    <button>
                                      {action.label}
                                    </button>
                                  </Link> : <Link to={{ pathname: `/faculty/assignment-review/${content.assignment_id}`, state: { action: action.label, subject: this.state.subject_label, id: this.state.subject_id, class_id: this.state.class_id } }}>
                                    <button>
                                      {action.label}
                                    </button>
                                  </Link>} </>
                                )}
                              </div>
                              }
                            </Link> : <div className="sm-item" key={content.content_id}>
                              {this.state.is_archive !== undefined &&
                                <Link className="sm-item" style={this.getColor(content)}
                                  to={{
                                    pathname: !this.state.is_faculty
                                      ? `/learner/classroom/${this.state.subject_id}`
                                      : `/faculty/classroom/${this.state.subject_id}`,
                                    state: { table_id: chapter.id, subject_label: this.state.subject_label, batch_id: this.state.batch_id, is_archive: true }
                                  }}
                                  onClick={() => this.props.getContentPreview(content.assignment_id ? content.assignment_id : content.content_id, content.content_subtype)}>
                                  <div className="sm-content-title ghghghgh">
                                    <img src={content.assignment_id ? Assignment : Target} id="sm-assessm" alt="img" />
                                    {!this.state.is_faculty && <span Style="margin-bottom: 10px;"><b Style={!this.contentScore(content.score) ? "display:none" : ""}>Score :</b>{this.contentScore(content.score)}<br /></span>}
                                    <span>{content.title}</span>
                                  </div>
                                </Link>
                              }
                              {this.state.is_archive == undefined &&
                                <Link className="sm-item" style={this.getColor(content)}
                                  to={{
                                    pathname: !this.state.is_faculty
                                      ? `/learner/classroom/${this.state.subject_id}`
                                      : `/faculty/classroom/${this.state.subject_id}`,
                                    state: { table_id: chapter.id, subject_label: this.state.subject_label, batch_id: this.state.batch_id, }
                                  }}
                                  onClick={() => this.props.getContentPreview(content.assignment_id ? content.assignment_id : content.content_id, content.content_subtype)}>
                                  <div className="sm-content-title ppppppp">
                                    <img src={content.assignment_id ? Assignment : Target} id="sm-assessm" alt="img" />
                                    {content.actions.length > 0 && content.actions.map(data =>
                                      !this.state.is_faculty && <div Style={(data?.label == "Review" || data?.label == "Result") ? '' : "display:none"}><span Style="margin-bottom: 10px;"><b Style={!this.contentScore(content.score) ? "display:none" : ""}>Score :</b>{this.contentScore(content.score)}<br /></span></div>
                                    )}

                                    {/* {!this.state.is_faculty &&<span Style="margin-bottom: 10px;"><b Style={!this.contentScore(content.score) ? "display:none" : ""}>Score :</b>{this.contentScore(content.score)}<br/></span>
                              } */}
                                    <span>{content.title}</span>
                                  </div>
                                </Link>
                              }

                              {content.actions.length > 0 && !this.state.is_faculty && content.content_subtype === 1 && <div className="sm-content-actions yyyyy">
                                {content.actions && content.actions.map(action =>
                                  <div key={action.id}
                                    onClick={() => this.attemptAndReview(action.label, content.content_id)}>

                                    {this.state.is_archive !== undefined && (action.label == "Review" || action.label == "Result") &&
                                      <button>{action.label}</button>
                                    }
                                    {this.state.is_archive == undefined &&
                                      <button>{action.label}</button>
                                    }



                                  </div>
                                )}
                              </div>}

                              {content.actions.length > 0 && !this.state.is_faculty && content.content_subtype === 0 && <div className="sm-content-actions ttttt">
                                {content.actions && content.actions.map(action =>
                                  <Link key={content.assignment_id} to={{
                                    pathname: `/learner/assignment-review/${content.assignment_id}`,
                                    state: { action: action.label, batch: this.state.batch_id, is_subject: 1, modal: true, class_id: this.state.class_id, subject: this.state.subject_label, id: this.state.subject_id }
                                  }}>
                                    <button>
                                      {action.label}
                                    </button>
                                  </Link>
                                )}
                              </div>}

                              {this.state.is_faculty && <div className="sm-content-actions qqqqq">
                                {content.actions && content.actions.map(action =>
                                  <>{content.content_subtype === 1 ? <Link key={content.content_id} to={{
                                    pathname: `/faculty/assessment-review/${content.content_id}`,
                                    state: { action: action.label, batch: this.state.batch_id, is_subject: 1, modal: true, class_id: this.state.class_id, subject: this.state.subject_label, id: this.state.subject_id }
                                  }}>
                                    <button>
                                      {action.label}
                                    </button>
                                  </Link> : <Link to={{ pathname: `/faculty/assignment-review/${content.assignment_id}`, state: { action: action.label, subject: this.state.subject_label, id: this.state.subject_id, class_id: this.state.class_id } }}>
                                    <button>
                                      {action.label}
                                    </button>
                                  </Link>
                                  }</>

                                )}
                              </div>
                              }
                            </div>}
                        </>
                        )}
                        {chapter.topics.length > 0 && <Link className="sm-more" to={{
                          pathname: !this.state.is_faculty
                            ? `/learner/study-material-topics/${this.state.subject_id}`
                            : `/faculty/study-material-topics/${this.state.subject_id}`,
                          state: {
                            topics: chapter.topics,
                            subject_label: this.state.subject_label
                          }
                        }}>
                          {this.props.saveLanguageWiseData.view_topics}
                        </Link>}
                      </Carousel>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    contentPreview: state.classroomReducer.contentPreview,
    saveLanguageWiseData: state.commonReducer.languageData,
    pagewiseLanguageData: state.commonReducer.pagewiseLanguageData
  }
}

const mapDispatchToProps = (dispatch) => {
  const { getStudentTOC, getContentPreview, getAttemptPreview, getLearnerProfile, getFacultyProfile, getStudentSessionsList, callzoomAttendanceAPI } = actions;
  return {
    getStudentTOC: (subject_id, is_archive) => dispatch(getStudentTOC(subject_id, is_archive)),
    getAttemptPreview: (contentId) => dispatch(getAttemptPreview(contentId)),
    getLearnerProfile: () => dispatch(getLearnerProfile()),
    getFacultyProfile: () => dispatch(getFacultyProfile()),
    getContentPreview: (contentId, content_subtype) => dispatch(getContentPreview(contentId, content_subtype)),
    getStudentSessionsList: (date, days, direction, data_range, subject_id) => dispatch(getStudentSessionsList(date, days, direction, data_range, 'true', subject_id)),
    callzoomAttendanceAPI: (lecture_id, user_id, join_time, join_type) => dispatch(callzoomAttendanceAPI(lecture_id, user_id, join_time, join_type)),

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StudyMaterial);