import common from '.';
import React from 'react';

// import Notes from '../public/icons/notes.svg';
// import Video from '../public/icons/video.svg';
// import Assessment from '../public/icons/assessmentx.svg';

import Notes from '../public/icons/notes.png';
import Video from '../public/icons/video.png';
import Assessment from '../public/icons/assessment.png';
import Assignment from '../public/icons/assignment.png';
import Scorm from '../public/icons/c_scorm.png';
import pdfIcon from '../public/icons/pdf-icon.png';
import docIcon from '../public/icons/doc-icon.png';
import pptIcon from '../public/icons/ppt-icon.png';
import zipIcon from '../public/icons/zip-icon.png';

const isUrl = require("is-valid-http-url");

const pad = num => {
  return ("0" + num).slice(-2);
}

const addFileUrl = (url) => {
  if (url !== undefined && url !== null && url.length > 0) {
    if (url.substring(0, 4) === 'http' || url.substring(0, 4) === 'blob')
      return url
    else
      return `${common.apiMediaDomain}${url}`
  }
  else {
    return '';
  }
}

const validateUrl = (url) => {
  if (isUrl(url)) {
    return true;
  }
  else {
    return false;
  }
}

const validateEmail = (email) => {
  if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
    return true;
  }
  else {
    return false;
  }
}

const getSelectedOptionColor = (selected_option_ids, id) => {
  let idPresent = 0;
  selected_option_ids.map(optionid => {
    return optionid === id ? idPresent++ : idPresent
  })
  if (idPresent)
    return 'solid 4px #56affc';
}

const getContentIcon = (type) => {
  switch (type) {
    case 0:
      return Assignment;

    case 1:
      return Assessment;
    case 3:
      return Scorm;
    case 4:
      return Notes;
    default:
      return Video;
  }
}


const getDocumentsIcon = (url) => {
 
  if (!url) {
    return <img style={{ height: '60px', width: '60px' }} src={pdfIcon} alt="Default Icon" />;
  }

  const fileExtension = url.split('.').pop().toLowerCase();

  switch (fileExtension) {
    case 'pdf':
      return <img style={{ height: '60px', width: '60px' }} src={pdfIcon} alt="PDF" />;
    case 'doc':
    case 'docx':
      return <img style={{ height: '60px', width: '60px' }} src={docIcon} alt="Document" />;
    case 'zip':
      return <img style={{ height: '60px', width: '60px' }} src={zipIcon} alt="ZIP" />;
    case 'ppt':
    case 'pptx':
      return <img style={{ height: '60px', width: '60px' }} src={pptIcon} alt="PowerPoint" />;
    default:
      return <img style={{ height: '60px', width: '60px' }} src={pdfIcon} alt="PDF" />;
  }
}


const checkForLastQuestion = (exerciseAttempt, currentQuestion) => {
  let { sections } = exerciseAttempt;
  let lastSection = sections[sections.length - 1]
  let lastQuestion = lastSection.questions[lastSection.questions.length - 1];
  if (lastQuestion.id === currentQuestion.id)
    return true
  else
    return false
}

function getFullDate(timestamp) {
  var date = new Date(timestamp);
  var datex = date.getDate();
  var month = date.getMonth() + 1;
  var year = date.getFullYear();
  return pad(datex) + "-" + pad(month) + "-" + year;
}

function getTimeFromDate(timestamp) {
  var date = new Date(timestamp);
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  return pad(hours) + ":" + pad(minutes) + " " + ampm
}

const checkForSameDate = (currentDate, lectureDate) => {
  let date = `${currentDate.getFullYear()}-${pad(currentDate.getMonth() + 1)}-${currentDate.getDate()}`;
  let lecture = `${lectureDate.getFullYear()}-${pad(lectureDate.getMonth() + 1)}-${lectureDate.getDate()}`;
  if (date === lecture)
    return true
  else
    return false
}

// const getQuestionColor = (status) => {

//   if(status.toLowerCase() == 'answered')
//     return '#56affc';
//   else if(status.toLowerCase() == 'not answered')
//     return '#ff8080';
//   else if(status.toLowerCase() == 'not visited')
//     return '#FFFFFF';
//   else if(status.toLowerCase() == 'to be reviewed')
//     return '#6236ff';
//   else
//     return '#c964e7';
// }


const getQuestionColor = (status) => {
  if (status.toLowerCase() == 'answered')
    return '#28a745'; // Green for answered
  else if (status.toLowerCase() == 'not answered')
    return '#dc3545'; // Red for not answered
  else if (status.toLowerCase() == 'not visited')
    return '#A0A0A0'; // Gray for not visited
  else if (status.toLowerCase() == 'to be reviewed')
    return '#ffc107'; // Yellow for to be reviewed
  else
    return '#c964e7'; // Default fallback color
}





// const getQuestionInstruction = (type) => {
//   if(type === "Multiple Choice Questions")
//       return "Choose one option"
//   else if(type === "Single Integer Questions")
//       return "Choose one option"
//   else if(type === "Multiple Response Questions")
//       return "Choose multiple answers"
//   else if(type === "Fill in the Blanks")
//       return "Choose option to fill in the blank"
//   else if(type === "True False")
//       return "Statement is"
//   else if(type === "Match the following")
//       return "Match the following"
//   else if(type === "Subjective")
//       return "Write your answer below"
// }

const getQuestionInstruction = (type, data) => {
  if (type === "Multiple Choice Questions")
    return data.choose_one_option
  else if (type === "Single Integer Questions")
    return data.choose_one_option
  else if (type === "Multiple Response Questions")
    return data.choose_multiple_answers
  else if (type === "Fill in the Blanks")
    return data.choose_option_to_fill_in_the_blank
  else if (type === "True False")
    return data.statement_is
  else if (type === "Match the following")
    return data.match_the_following
  else if (type === "Subjective")
    return data.write_your_answer_below
}

const getContentIds = (toc_units) => {
  var result = {};
  var keyList = [];
  for (let i = 0; i < toc_units.length; i++) {
    var chapters = toc_units[i].chapters;
    result[toc_units[i].id] = toc_units[i].content;
    keyList.push(toc_units[i].id)
    for (let j = 0; j < chapters.length; j++) {
      var topics = chapters[j].topics;
      result[chapters[j].id] = chapters[j].content;
      keyList.push(chapters[j].id)
      for (let k = 0; k < topics.length; k++) {
        var subtopics = topics[k].subtopics;
        result[topics[k].id] = topics[k].content;
        keyList.push(topics[k].id)
        for (let l = 0; l < subtopics.length; l++) {
          result[subtopics[l].id] = subtopics[l].content;
          keyList.push(subtopics[l].id)
        }
      }
    }
  }
  return result;
}

const getContentIdList = (toc_units) => {
  var result = {};
  var keyList = [];
  for (let i = 0; i < toc_units.length; i++) {
    var chapters = toc_units[i].chapters;
    result[toc_units[i].id] = toc_units[i].content;
    keyList.push(toc_units[i].id)
    for (let j = 0; j < chapters.length; j++) {
      var topics = chapters[j].topics;
      result[chapters[j].id] = chapters[j].content;
      keyList.push(chapters[j].id)
      for (let k = 0; k < topics.length; k++) {
        var subtopics = topics[k].subtopics;
        result[topics[k].id] = topics[k].content;
        keyList.push(topics[k].id)
        for (let l = 0; l < subtopics.length; l++) {
          result[subtopics[l].id] = subtopics[l].content;
          keyList.push(subtopics[l].id)
        }
      }
    }
  }

  console.log(keyList)
  return keyList;
}



const insertExpandedFlag = (toc_units, openDefault) => {
  let totalUnits = toc_units.toc_data.units;
  if (toc_units.length !== 0) {
    for (let i = 0; i < totalUnits.length; i++) {
      // totalUnits[i].expanded = totalUnits[i]['id']==toc_units.current_open_toc_id
      if (i === 0)
        totalUnits[i].expanded = openDefault
      else
        totalUnits[i].expanded = openDefault
      let chapters = totalUnits[i].chapters
      if (chapters.length !== 0) {
        for (let j = 0; j < chapters.length; j++) {
          let topics
          if (i === 0) {
            totalUnits[i].chapters[j].expanded = openDefault
            topics = totalUnits[i].chapters[j].topics
            topics = topics.map(topic => ({ ...topic, expanded: openDefault }))
          }
          else {
            totalUnits[i].chapters[j].expanded = openDefault
            topics = totalUnits[i].chapters[j].topics
            topics = topics.map(topic => ({ ...topic, expanded: openDefault }))
          }
          totalUnits[i].chapters[j].topics = topics
        }
      }
    }
  }
  return totalUnits
}

const insertCheckedFlag = (toc_units) => {
  let totalUnits = toc_units;
  if (totalUnits.length !== 0) {
    for (let i = 0; i < totalUnits.length; i++) {
      totalUnits[i].content.map(file => {
        file.checked = file.checked ? file.checked : true
      })
      totalUnits[i].chapters.map(chapter => {
        chapter.content.map(file => {
          file.checked = file.checked ? file.checked : true
        })
        chapter.topics.map(topic => {
          topic.checked = topic.checked ? topic.checked : true
          topic.content.map(file => {
            file.checked = file.checked ? file.checked : true
          })
          topic.subtopics.map(subtopic => {
            subtopic.checked = subtopic.checked ? subtopic.checked : true
            subtopic.content.map(file => {
              file.checked = file.checked ? file.checked : true
            })
          })
        })
      })
    }
  }
  return totalUnits
}

const toggleExpandedFlag = (toc_lists, unitId, chapterId = null, topicId = null, subtopicId = null) => {
  let preUnitChapter = toc_lists
  let togIndex = toc_lists.findIndex(p => p.id == unitId)
  if (subtopicId) {
    let togChapterIndex = preUnitChapter[togIndex].chapters.findIndex(p => p.id == chapterId)
    let togTopicIndex = preUnitChapter[togIndex].chapters[togChapterIndex].topics.findIndex(p => p.id == topicId)
    let togSubtopicIndex = preUnitChapter[togIndex].chapters[togChapterIndex].topics[togTopicIndex].subtopics.findIndex(p => p.id === subtopicId)
    preUnitChapter[togIndex].chapters[togChapterIndex].topics[togTopicIndex].subtopics[togSubtopicIndex].expanded = !toc_lists[togIndex].chapters[togChapterIndex].topics[togTopicIndex].subtopics[togSubtopicIndex].expanded
    return preUnitChapter
  }
  else if (topicId) {
    let togChapterIndex = preUnitChapter[togIndex].chapters.findIndex(p => p.id == chapterId)
    let togTopicIndex = preUnitChapter[togIndex].chapters[togChapterIndex].topics.findIndex(p => p.id == topicId)
    preUnitChapter[togIndex].chapters[togChapterIndex].topics[togTopicIndex].expanded = !toc_lists[togIndex].chapters[togChapterIndex].topics[togTopicIndex].expanded
    return preUnitChapter
  }
  else if (chapterId) {
    let togChapterIndex = preUnitChapter[togIndex].chapters.findIndex(p => p.id == chapterId)
    preUnitChapter[togIndex].chapters[togChapterIndex].expanded = !toc_lists[togIndex].chapters[togChapterIndex].expanded
    preUnitChapter[togIndex].chapters.map(chapter => {
      chapter.topics.map(topic => {
        topic.expanded = true
        return topic
      })
    })
    return preUnitChapter
  }
  else {
    preUnitChapter[togIndex].expanded = !toc_lists[togIndex].expanded
    return preUnitChapter
  }
}

export default {
  pad,
  getFullDate,
  getTimeFromDate,
  checkForSameDate,
  addFileUrl,
  validateUrl,
  validateEmail,
  getSelectedOptionColor,
  getContentIcon,
  getQuestionColor,
  getQuestionInstruction,
  checkForLastQuestion,
  getContentIds,
  getContentIdList,
  insertCheckedFlag,
  insertExpandedFlag,
  toggleExpandedFlag,
  getDocumentsIcon
}