import React, { useEffect, useState } from "react";
import { useDispatch, connect } from "react-redux";
import axios from "axios";
import reportsLoader from "../../public/icons/loader.png";
import "./UserLog.css";
import Header from "../../components/navigation/Header";
import useDebounce from "../../hooks/debounce";
import "./AssessmentRecord.css";
import actions from "../../redux/actions";


const AssessmentRecord = (props) => {


  const [assessmentList, setAssessmentList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [paginationDetail, setPaginationDetail] = useState({
    current_page: 1,
    total_page: 0,
    count: 0,
    page_size: 0,
    hasNextPage: false,
    hasPreviousPage: false,
  });

  const debouncedQuery = useDebounce(searchQuery, 500);
  let getToken = localStorage.getItem("token");

  const API_URL = "https://vstageapi.learningoxygen.com/api/v1/content/assessment-list?page=1";

  const getReport = async (created_from, created_to, name, page = 1) => {
    setLoading(true);
    setError(null);

    const requestBody = {
      created_from,
      created_to,
      name,
    };

    try {
      console.log("Sending POST request with data:", requestBody);

      const response = await axios.post(API_URL, requestBody, {
        headers: {
          Authorization: `token ${getToken}`,
          "Content-Type": "application/json",
        },
      });

      console.log("Received Data:", response?.data?.data);

      if (Array.isArray(response?.data?.data?.data)) {
        setAssessmentList(response?.data?.data?.data);
      } else {
        setAssessmentList([]);
        setError("No assessments found for the given criteria.");
      }

      const pagination = response?.data?.data?.pagination || {};
      setPaginationDetail({
        ...pagination,
        current_page: page,
        hasNextPage: pagination?.next !== null,
        hasPreviousPage: pagination?.previous !== null,
      });

      setError(null);
    } catch (e) {
      console.error("Error fetching report:", e);
      setError("Failed to fetch data. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = () => {
    getReport(startDate, endDate, debouncedQuery, paginationDetail.current_page);
  };

  const handleStartDateChange = (e) => setStartDate(e.target.value);
  const handleEndDateChange = (e) => setEndDate(e.target.value);
  const handleSearchChange = (e) => setSearchQuery(e.target.value);

  const handleNextPage = () => {
    if (paginationDetail?.hasNextPage) {
      const nextPage = paginationDetail.current_page + 1;
      getReport(startDate, endDate, debouncedQuery, nextPage);
    }
  };

  const handlePreviousPage = () => {
    if (paginationDetail?.hasPreviousPage) {
      const previousPage = paginationDetail.current_page - 1;
      getReport(startDate, endDate, debouncedQuery, previousPage);
    }
  };


  const handleViewAction = (assessment_id, currentPage) => {
    window.location.href = `/admin/user-assessment?assessment_id=${assessment_id}&currentPage=${currentPage}`;
  };

  return (
    <div className="admin-div">
      <Header placeholder="Search classes" />
      <h1>Assessment Report</h1>
      <div className="filter-section">
        <input
          type="text"
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder="Search Assessment"
          className="search-input"
        />
        <div className="date-filters">
          <label>
            Start Date:
            <input
              type="date"
              value={startDate}
              onChange={handleStartDateChange}
              style={{ width: "100%", marginBottom: "10px", padding: "5px" }}
            />
          </label>
          <label>
            End Date:
            <input
              type="date"
              value={endDate}
              onChange={handleEndDateChange}
              style={{ width: "100%", marginBottom: "10px", padding: "5px" }}
            />
          </label>
        </div>
        <button onClick={handleSearch}>Search</button>
      </div>

      {loading && (
        <div className="loading">
          <img src={reportsLoader} alt="Loading..." />
        </div>
      )}

      {error && <div className="error-message">{error}</div>}

      {assessmentList.length > 0 ? (
        <div
          className="table-container"
          style={{ overflowY: "scroll", maxHeight: "450px" }}
        >
          <table className="assessment-table" style={{ width: "100%" }}>
            <thead>
              <tr
                style={{
                  position: "sticky",
                  top: 0,
                  background: "#fff",
                  zIndex: 1,
                }}
              >
                <th>Title</th>
                <th>Start Date</th>
                <th>Subject</th>
                <th>Semester</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {assessmentList.map((item) => (
                <tr key={item.id}>
                  <td>{item.title ?? "N/A"}</td>
                  <td>
                    {new Date(item.start_date ?? "").toLocaleDateString()}
                  </td>
                  <td>{item.subject_name ?? "N/A"}</td>
                  <td>{item.semester_name ?? "N/A"}</td>
                  <td>
                    <a
                      href=""
                      onClick={(e) => {
                        e.preventDefault();
                        handleViewAction(item.id, paginationDetail.current_page);
                      }}
                    >
                      View
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        !loading && !error && (
          <div className="no-data-message">No assessments found</div>
        )
      )}

      {assessmentList.length > 0 && (
        <div
          style={{
            display: "flex",
            flex: "row",
            gap: "20px",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <button
            onClick={handlePreviousPage}
            disabled={!paginationDetail?.hasPreviousPage}
          >
            Previous
          </button>
          <button
            onClick={handleNextPage}
            disabled={!paginationDetail?.hasNextPage}
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
};



export default AssessmentRecord; 
