import React from 'react';
import config from '../../../config';
import ReactNotifications, { store } from 'react-notifications-component';
import videojs from 'video.js';

import { connect } from 'react-redux';
import qualitySelector from 'videojs-hls-quality-selector';
import SkipFw from '../../../public/icons/skip-fw.svg';
import GoBack from '../../../public/icons/go-back.svg';
import actions from '../../../redux/actions';
import _ from 'lodash';
import './videoPlayer.css';
import { ca } from 'date-fns/locale';
window.videojs = videojs;



//require('videojs-contrib-hls/dist/videojs-contrib-hls.js');

const surveyjson = config?.surveyjson;
const surveydata = JSON.parse(surveyjson);

class Videojs extends React.Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.state = {
      lastDuration: 0,
      url: this.props.sources[0].src,
      updateCount: 0,
      rewind: false,
      forward: false,
      content_id: this.props.content_id,
      ref_id: this.props.ref_id,
      level: this.props.level,
      content_type: this.props.content_type,
      content_subtype: this.props.content_subtype,
      subject: this.props.subject_id,
      current_pointer: this.props.current_pointer,
      is_faculty: localStorage.getItem("is_faculty") == "true" ? true : false,
      is_admin: localStorage.getItem("is_admin") == "true" ? true : false,

      saveVisibilityTypeVideo: surveydata?.response?.visibility_rules?.video ? surveydata?.response.visibility_rules?.video : '',
      saveVideoElement: document.querySelector('.content-section .video-container .vjs-tech'),
      saveVideoElementId: '',
      videoSurvayDialogSkipByUser: false,
      videoSurvayDialogClosedByUser: false,
      isSurveyInputChecked: false,
      videoSurvayDialogOpen: false,
      videoSurvayThanksDialogOpen: false,
      survey_id: '',
      responses: [],
      center_id: localStorage.getItem('center_id'),
      meetcondition: false
    }
  }

  async componentDidMount() {
    this.setup();

    videojs.registerPlugin('hlsQualitySelector', qualitySelector);
    this.player = videojs(this.videoNode, this.props, function onPlayerReady() {
      console.log('onPlayerReady45', this);

    });

    localStorage.setItem("video_played_time", this.player.currentTime() ? this.player.currentTime() : this.state.current_pointer ? this.state.current_pointer : this.props.wVal);
    this.player.currentTime(localStorage.getItem("video_played_time"))
    this.player.hlsQualitySelector({ displayCurrentQuality: true });

    window.addEventListener('beforeunload', this.saveState);

    // console.log("this.state.saveVisibilityType in did mount", this.state.saveVisibilityTypeVideo);



    if (config?.surveyAllowedOrg_ids?.includes(parseInt(this.state.center_id))) {
      console.log("ID", this.state.center_id, "is allowed. Proceed with the code.");
      if (this.state.saveVisibilityTypeVideo !== '') {
        // console.log("survaySubmitted", localStorage.getItem(this.state.content_id+"survaySubmitted"));
        setTimeout(() => {
          this.handleDocumentLoad();
        }, 1000);
      }
    } else {
      console.log("ID", this.state.center_id, "is not allowed")
    }

    // add right and left key listener
    document.addEventListener('keydown', (event) => {
      if (event.keyCode === 37) {
        this.rewind(-10)
      } else if (event.keyCode === 39) {
        this.forward(10)
      }
    });




  }

  // check_id(id_to_check){
  //   if (config?.surveyAllowedOrg_ids?.includes(id_to_check)) {
  //       return true
  //   }else{
  //       return false
  //   }
  // }

  handleResponseChange = (questionId, response, time) => {
    // Check if a response for the given question already exists
    const existingResponseIndex = this.state.responses.findIndex(res => res.question_id === questionId);

    // If a response already exists, update it; otherwise, add a new response
    if (existingResponseIndex !== -1) {
      const updatedResponses = [...this.state.responses];
      updatedResponses[existingResponseIndex] = { question_id: questionId, response: response, time: time };
      this.setState({
        responses: updatedResponses,
        isSurveyInputChecked: true
      });
    } else {
      const newResponse = { question_id: questionId, response: response, time: time };
      this.setState(prevState => ({
        responses: [...prevState.responses, newResponse],
        isSurveyInputChecked: true
      }));
    }
  };





  onSumbitSurvay = async () => {
    const response = await this.props.userSurvayCompletion(surveydata?.response.survey_id, this.state.content_id, this.state.responses);
    if (response !== undefined) {
      this.setState({ videoSurvayDialogOpen: false });
      this.setState({ videoSurvayThanksDialogOpen: true });
      localStorage.setItem(this.state.content_id + "survaySubmitted", 1);
      console.log("response.error");
      console.log(response.error);
    }
  }

  videoSurvayDialogClose = () => {
    this.setState({ videoSurvayDialogOpen: false });
    this.setState({ videoSurvayDialogClosedByUser: true });
    if (this.state.saveVideoElement) {
      if (this.state.meetcondition && localStorage.getItem(this.state.content_id + "survaySubmitted") === null) {
        var myPlayer = videojs(this.state.saveVideoElement);
        setTimeout(() => {
          var myPlayer = videojs(this.state.saveVideoElement);
          if (myPlayer) {
            myPlayer.play(); // Ensure that the video resumes playing
          }
        }, 500);
        setTimeout(() => {
          var myPlayer = videojs(this.state.saveVideoElement);
          if (myPlayer && !myPlayer?.paused()) {
            this.checkDialogReopen();
          }
        }, 60000);
      }
    } else {
      console.error("saveVideoElement is null or undefined");
    }
  }

  onSurvaySkip = () => {
    this.setState({ videoSurvayDialogOpen: false });
    localStorage.setItem(this.state.content_id + "survaySubmitted", 1);
    var myPlayer = videojs(this.state.saveVideoElement);
    this.setState({ videoSurvayDialogSkipByUser: true });
    setTimeout(() => {
      myPlayer.play();
    }, 500);
  };

  videoSurvayThanksDialogClose = () => {
    this.setState({ videoSurvayThanksDialogOpen: false });
    var myPlayer = videojs(this.state.saveVideoElement);
    setTimeout(() => {
      myPlayer.play();
    }, 500);


  };

  handleDocumentLoad = () => {
    // Access the video element here
    // console.log("videoElement loaded");
    const videoElement = document.querySelector('.content-section .video-container .vjs-tech');
    const videoElementId = videoElement ? videoElement.id : null;
    if (videoElement) {
      //console.log("saveVisibilityType",this.state.saveVisibilityTypeVideo);
      this.setState({ saveVideoElement: videoElement });
      this.setState({ saveVideoElementId: videoElementId });
      //console.log('Video element found:', videoElement);
      if (localStorage.getItem(this.state.content_id + "survaySubmitted") === null) {
        videoElement.addEventListener('timeupdate', this.checkVideoProgress());
      }
    } else {
      console.error('Video element not found.');
    }
  }

  checkVideoProgress = () => {
    //console.log('Checking video progress'); 
    // const videoElement = document.querySelector('.content-section .video-container .vjs-tech');
    if (this.state.saveVideoElement && this.state.saveVideoElement.duration) {
      this.state.saveVideoElement.addEventListener('timeupdate', this.handleSeekBarChange);
    } else {
      console.warn('Video element or duration not available');
    }
  }


  handleSeekBarChange = () => {
    let getSurveySubmitStatus = localStorage.getItem(this.state.content_id + "survaySubmitted");
    if (config?.surveyAllowedOrg_ids?.includes(parseInt(this.state.center_id)) && getSurveySubmitStatus == null) {
      //console.log('Seek bar position changed');
      const { content_id, responses } = this.state;
      const { visibility_rules } = surveydata?.response || {};
      const { video } = visibility_rules || {};
      const { played } = video || {};
      const conditions = surveydata?.response.visibility_rules.video.condition || {};
      //console.log("played", played);
      //console.log("conditions", conditions);
      if (!this.state.saveVideoElement) {
        console.log('Video has not reached');
        return;
      }

      const duration = this.state.saveVideoElement.duration;
      const currentTime = this.state.saveVideoElement.currentTime;
      const completionPercentage = (currentTime / duration) * 100;
      //console.log("Duration:", duration);
      //console.log("Current Time:", currentTime);
      //console.log("Completion Percentage:", completionPercentage);


      //console.log("Survey Submit Status:", getSurveySubmitStatus);

      const conditionString = `${completionPercentage} ${conditions} ${played}`;

      if (eval(conditionString)) {
        this.setState({ meetcondition: true });
        const myPlayer = videojs(this.state.saveVideoElement);

        if (getSurveySubmitStatus === null && !this.state.videoSurvayDialogClosedByUser && !this.state.videoSurvayDialogSkipByUser) {
          if (this.state.videoSurvayDialogOpen) {
            myPlayer.pause();
            this.setState({ videoSurvayDialogOpen: true });
            console.log("Enter case 1 if");
          } else {
            myPlayer.play();
            this.setState({ videoSurvayDialogOpen: true });
            console.log("Enter case 1 else");
          }
        } else if (getSurveySubmitStatus !== null && !this.state.videoSurvayDialogSkipByUser && !this.state.videoSurvayDialogClosedByUser) {
          if (this.state.saveVideoElement) {
            let myPlayers = videojs(this.state.saveVideoElement);
            if (myPlayers) {
              setTimeout(() => { myPlayers.play(); }, 500);
              this.setState({ videoSurvayDialogOpen: true });
              console.log("Enter case 2");
            }
          }
        } else if (getSurveySubmitStatus === null && !this.state.videoSurvayDialogSkipByUser && this.state.videoSurvayDialogClosedByUser) {
          // if (this.state.videoSurvayDialogOpen && myPlayer.paused()) {
          //   setTimeout(() => { myPlayer.play(); }, 500);
          //   console.log("Enter case 3");
          // }
        } else if (getSurveySubmitStatus === null && this.state.videoSurvayDialogSkipByUser && this.state.videoSurvayDialogOpen) {
          console.log("Enter case 4");
        }
      } else {
        this.setState({ meetcondition: false })
      }
    }
  }


  checkDialogReopen = () => {
    // If dialog is closed, reopen it
    if (!this.state.videoSurvayDialogOpen) {
      this.setState({ videoSurvayDialogOpen: true });
    }

    if (this.state.videoSurvayDialogOpen) {
      const myPlayer = videojs(this.state.saveVideoElement);
      myPlayer.pause();

    }

    if (!this.state.meetcondition) {
      const myPlayer = videojs(this.state.saveVideoElement);
      this.setState({ videoSurvayDialogOpen: false });
      this.setState({ videoSurvayDialogClosedByUser: false });
      // console.log("222222222222222222222");
      myPlayer.play();
    }
  };

  saveState = (ev) => {
    ev.preventDefault();
    console.log("amitpandey")
    this.consumedVideo();
    return ev.returnValue = 'Are you sure you want to close?';
  }

  scheduledOn = (val) => {
    console.log(val)
  }

  componentWillReceiveProps(nextProps) {
    // You should probably change this check
    if (this.props !== nextProps)
      this.setup();
  }

  async componentDidUpdate(prevProps, prevState) {
    console.log("amit" + this.props.wVal)
    console.log("Hello" + this.props.wVal);

    localStorage.setItem("video_played_time", this.player.currentTime() ? this.player.currentTime() : this.state.current_pointer ? this.state.current_pointer : this.props.wVal);

    if (this.player) {
      setTimeout(() => {
        this.consumedVideo();
        // console.log("dispose"+this.player.currentTime())
        // console.log("Component did mount"+this.player.currentTime())
        //this.player.dispose();
        // console.log(this.player.duration())
      }, 3000);

    }

    if (this.state.updateCount !== prevState.updateCount) {

      if (this.player) {
        this.player.dispose();
      }
      // Create new player
      this.player = videojs(this.videoNode, this.props);
      
      this.player.hlsQualitySelector({ displayCurrentQuality: true });

      // check when video is loaded and have duration
      this.player.on('loadedmetadata', async () => {
        console.log('loadedmetadata');
        console.log("readu ", this.player.duration())
        const { content_id, ref_id, level, content_type, content_subtype, subject } = this.state;

        try {
          const response = await this.props.userContentCompletion(
            content_id,
            ref_id,
            level,
            content_type,
            content_subtype,
            0,
            parseInt(this.player.duration()),
            parseInt(this.player.duration()),
            parseInt(this.player.duration()),
            parseInt(subject)
          );
          this.setState({ lastDuration: response?.data?.max_page_no || 0 });
          this.player.currentTime(response?.data?.max_page_no || 0)
          this.player.play();
        } catch (error) {
          console.log("error", error);
        }
      });
    }
  }



  // destroy player on unmount
  componentWillUnmount() {
    if (this.player) {
      this.consumedVideo();
      console.log("dispose" + this.player.currentTime())
      this.player.dispose();
    }
    document.removeEventListener('beforeunload', this.saveState);

    clearInterval(this.dialogReopenInterval);

  }


  consumedVideo = async () => {
    const { content_id, ref_id, level, content_type, content_subtype, subject } = this.state;

    try {
      if (this.player.paused()) {
        return;
      }
   

      if (!this.state.is_faculty && !this.state.is_admin) {
        const response = await this.props.userContentCompletion(
          content_id,
          ref_id,
          level,
          content_type,
          content_subtype,
          parseInt(this.player.currentTime()),
          parseInt(this.player.duration()),
          parseInt(this.player.duration()),
          parseInt(this.player.duration()),
          parseInt(subject)
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  setup() {
    let updateCount = this.state.updateCount;
    this.setState({
      updateCount: updateCount + 1
    });
  }

  pausePlay() {
    if (this.player.paused()) {
      this.player.play()
    }
    else {
      this.player.pause();
    }

    console.log(this.player.currentTime())
  }

  delay = (ms) => new Promise(res => setTimeout(res, ms));

  seek(secs) {
    console.log(this.player.currentTime() + "/" + secs)
    let time = this.player.currentTime() + secs;
    if (time < 0) {
      time = 0;
    }
    console.log(time)
    this.player.currentTime(time);
  }

  forward = async () => {
    this.seek(10);
    this.setState({ forward: true })
    await this.delay(200);
    this.setState({ forward: false })
  }

  rewind = async () => {
    this.seek(-10);
    this.setState({ rewind: true })
    await this.delay(200);
    this.setState({ rewind: false })
  }

  render() {
    const key = `${this.state.updateCount}`;
    const { isSurveyInputChecked } = this.state;

    return (

      <div>
        <ReactNotifications />

        <div className={this.state.videoSurvayThanksDialogOpen ? 'videoSurvayThankuDialog open' : 'videoSurvayThankuDialog'}>
          <div className='videoSurvayThankuDialogContainer'>
            <h3>Thank you for your precious feedback</h3>
            <p>Your opinion is important to us. Your answers will be used for improving our services to you.</p>
            <div className='formActions'><button onClick={this.videoSurvayThanksDialogClose}>Close</button></div>
          </div>
        </div>

        <div className={this.state.videoSurvayDialogOpen ? 'videoSurvayDialog open' : 'videoSurvayDialog'}>
          <div className='videoSurvayContainer'>
            <div className='dialogClose' onClick={this.videoSurvayDialogClose}><span><i className='fa fa-times'></i></span></div>
            <h3>Survey Form</h3>
            {/* {JSON.stringify(surveydata?.response?.questions)} */}

            <div className='contentWrapper'>
              <ol>
                {surveydata?.response?.questions && surveydata?.response?.questions.length > 0 && surveydata?.response?.questions.map((data, index) =>
                  <li key={data.question_id}> {/* Assuming question_id is unique */}
                    <div className='question'>{data.question}</div>
                    <div className='rating'>
                      {data?.options.map((dataoptions, index) =>
                        <label key={index}>
                          <input
                            type="radio"
                            name={`question${data.question_id}`}
                            value={dataoptions}
                            onChange={e => this.handleResponseChange(data.question_id, parseInt(e.target.value), null)}
                          />
                          <span>{dataoptions}</span>
                        </label>
                      )}
                    </div>
                  </li>
                )}

              </ol>
              <div className='formActions'><button onClick={this.onSumbitSurvay} disabled={!isSurveyInputChecked}>Submit</button><span onClick={this.onSurvaySkip}>Skip</span></div>
            </div>
          </div>

        </div>

        <div className="video-container helloVideoWrapper">
          <div key={key} data-vjs-player>
            <video preload="auto" ref={node => (this.videoNode = node)} className=" helloVideo video-js vjs-big-play-centered
            vjs-default-skin"/>
            <button onClick={() => this.pausePlay()} id="play_pause" className='playpause'></button>
            <button onClick={() => this.rewind(-10)} id="rewind">
              <img src={GoBack} style={this.state.rewind ? { display: 'block' }
                : { display: 'none' }} alt="rwd" />
            </button>
            <button onClick={() => this.forward(10)} id="forward">
              <img src={SkipFw} style={this.state.forward ? { display: 'block' }
                : { display: 'none' }} alt="fwd" />
            </button>
          </div>
        </div>

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
  }
}

const mapDispatchToProps = (dispatch) => {
  const { userContentCompletion, userSurvayCompletion } = actions;
  return {
    userContentCompletion: (content, ref_id, level, content_type, content_subtype, page_number, max_page_no, duration_taken, previous_page_number, subject) => dispatch(userContentCompletion(content, ref_id, level, content_type, content_subtype, page_number, max_page_no, duration_taken, previous_page_number, subject)),
    userSurvayCompletion: (survey_id, content_id, responses) => dispatch(userSurvayCompletion(survey_id, content_id, responses)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Videojs);