
const apiDomain = "https://api.verandahighered.com";
//const apiMediaDomain = 'http://beta.schoolsonweb.com';
const apiMediaDomain='https://api.verandahighered.com';
const wpDomain = 'http://sow.learningoxygen.com/wp-json/wc/v3/';
const logoutPage='lms.verandahighered.com';
const homePage='lms.verandahighered.com';
const wpUsername='admin';
const lmsBaseUrl= 'http://127.0.0.1:8001/api';
const wpPassword='A&J9%SQg^#Tf)RF#Y^JWI)OR';
const liveClassContentCreationUrl="https://virtualuniversity.imaginxp.live/liveclassContentlogin?a=b&secret=";
const sessionTimeOutTime=1800000;
const cdnUrl= 'cdn.verandahighered.com';

//const sessionTimeOutTime=1800;

const brandLogo='https://cdn.verandahighered.com/center-logos/sastra.png';
const themePrimaryColor='#2e3192';
const themeSecondaryColor='#000000';
const themePrimaryLightColor='#00aeed4d';
const loginSiderBanner1 = 'login-banner1.jpg'
const loginSiderBanner2 = 'login-banner2.jpg'
const loginSiderBanner3 = 'login-banner3.jpg'
const loginSiderBanner4 = 'login-banner4.jpg';
const surveyjson = `{"response": {"survey_id": 1,"questions": [{"question_id": 1,"type": "rating","question": "The content quality and depth of information of the videos are good","options": [1,2,3,4,5]},{"question_id": 2,"type": "rating","question": "The faculty has speech clarity and language proficiency.","options": [1,2,3,
    4,5]},{"question_id": 3,"type": "rating","question": "The presentation  and content delivery of the lectures are well-structured",
    "options": [1,2,3,4,5]},{"question_id": 4,"type": "rating","question": "The slides have relevant content.","options": [
    1,2,3,4,5]},{"question_id": 5, "type": "rating", "question": "The duration of the lecture is adequate.",
    "options": [1,2,3,4,5]}],"visibility_rules": {"video": {"played":90, "condition": ">"}}}}`;
const surveyAllowedOrg_ids =  [3]
const facultySidebarHidden_ids =  [40]

export default {
    lmsBaseUrl,
    apiDomain,
    apiMediaDomain,
    wpDomain,
    logoutPage,
    homePage,
    wpUsername,
    wpPassword,
    liveClassContentCreationUrl,
    sessionTimeOutTime,
    themePrimaryColor,
    themeSecondaryColor,
    themePrimaryLightColor,
    brandLogo,
    loginSiderBanner1,
    loginSiderBanner2,
    loginSiderBanner3,
    loginSiderBanner4,
    surveyjson,
    surveyAllowedOrg_ids,
    facultySidebarHidden_ids,
    cdnUrl

}
