import React from 'react'

const Analytics = () => {
  return (
    <div className='admin-div'>
        <iframe style={{padding: '20px 0px'}} src="https://dbuni.edulystventures.com/login-with-token?token=c2Frc2hhbW1lYzAyQG1haWxpbmF0b3IuY29t" width="100%" height="1000" frameborder="0"></iframe>
    </div>
  )
}

export default Analytics